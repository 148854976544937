// 3rd Party
import * as React from 'react';

// Utility References
//import { } from 'Utility/IndexOfComponents';
import { TimeOfDayCategory } from 'Utility/IndexOfEnums';
import { IApiItemOptionViewModel } from 'Utility/IndexOfInterfaces';
import { DateConverter } from 'Utility/IndexOfModels';

// App References
//import { } from 'App/IndexOfInterfaces';
//import { } from 'App/IndexOfModels';

// Private Functions

const enumRequiresTimeValue = (timeOfDayId: number): boolean => {
    return timeOfDayId === TimeOfDayCategory.Time;
};

const getTimePeriod = (timePeriods: IApiItemOptionViewModel[], selectedId: number) => {
    return timePeriods.filter(x => x.value === selectedId.toString())[0];
};

// Public Functions

const optionRequiresTimeValue = (timePeriods: IApiItemOptionViewModel[], selectedTimeOfDayId: number): boolean => {

    if (selectedTimeOfDayId) {

        const timePeriod = getTimePeriod(timePeriods, selectedTimeOfDayId);
        const timeOfDayId = parseInt(timePeriod.value, 10);
        return enumRequiresTimeValue(timeOfDayId);
    }

    return false;
};

const removeFromOptions = (timePeriods: IApiItemOptionViewModel[], timePeriodToExclude: TimeOfDayCategory): IApiItemOptionViewModel[] => {
    return timePeriods.filter(i => i.value !== timePeriodToExclude.toString());
};

const convertOptionToText = (timePeriods: IApiItemOptionViewModel[], selectedTimeOfDayId: number, timeValue?: Date): string => {

    const timePeriod = getTimePeriod(timePeriods, selectedTimeOfDayId);

    const justText = !timeValue || !optionRequiresTimeValue(timePeriods, selectedTimeOfDayId);

    if (justText) {
        return timePeriod.text;
    }

    return DateConverter(timeValue).to24HrTimeDisplay();
};

const timeOfDayHelper = {
    convertOptionToText,
    optionRequiresTimeValue,
    removeFromOptions
};

export { timeOfDayHelper as TimeOfDayHelper };
