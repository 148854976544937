 import { IApiItemOptionViewModel } from '../IndexOfInterfaces';
 import { Notification } from '../IndexOfServices';

// Direct References
 import { StringArrayHelper } from './ArrayHelper';

 const itemOptionHelper = (itemOptions: IApiItemOptionViewModel[]) => {

    (() => {

        if (!itemOptions) {
            Notification.error.throw('Utility Code - ItemOptionHelper', 'Creation', 'Argument Null Exception: items');
        }

    })();

    const assertAllItemsUnique = (): void => {

        const allValuesUnique = StringArrayHelper(itemOptions.map(x => x.value)).checkAllValuesUnique();

        if (!allValuesUnique) {
            Notification.error.throw('Utility Code - ItemOptionHelper',
                'Check All Options Unique',
                `Duplicate Value Found in ItemOptions - 1st item ${itemOptions[0].text}`);
        }

        //TODOxUI: We should re-enable this once our data structures/validations prohibit duplicate items.
        //There should be no scenarios where a user has to select from a list containing repeated values.
        //To avoid this, concat more values to select text: e.g. Main Street - Edinburgh, Main Street - Glasgow
        /*const allTextUnique = StringArrayHelper(itemOptions.map(x => x.text)).checkAllValuesUnique();

        if (!allTextUnique) {
            Notification.error.throw('Utility Code - ItemOptionHelper',
                'Check All Options Unique',
                `Duplicate Text Found in ItemOptions - 1st item ${itemOptions[0].text}`);
        }*/
    };

    const setOptionValuesFromOptionText = (): IApiItemOptionViewModel[] => {

        itemOptions.forEach((option: IApiItemOptionViewModel) => {

            option.value = option.text;
        });

        return itemOptions;
    };

    return {
        assertAllItemsUnique,
        setOptionValuesFromOptionText
    };
};

 export {
    itemOptionHelper as ItemOptionHelper
};
