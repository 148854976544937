const clearStorage = (): void => {

    localStorage.clear();
};

const removeItem = (key: string): void => {

    localStorage.removeItem(key);
};

const retrieveNumber = (key: string): number => {

    const value = retrieveString(key);
    return value && parseInt(value, 10);
};

const retrieveObject = (key: string): any => {

    const value = retrieveString(key);
    return value && JSON.parse(value);
};

const retrieveString = (key: string, valueIfNull?: string): string => {

    const result = localStorage.getItem(key);
    return result || valueIfNull || null;
};

const storeNumber = (key: string, value: number): void => {

    storeString(key, value.toString());
};

const storeObject = (key: string, value: any): void => {

    storeString(key, JSON.stringify(value));
};

const storeString = (key: string, value: string): void => {

    localStorage.setItem(key, value);
};

const localStorageHelper = {
    clearStorage,
    removeItem,
    retrieveNumber,
    retrieveObject,
    retrieveString,
    storeNumber,
    storeObject,
    storeString
};

export { localStorageHelper as LocalStorageHelper };
