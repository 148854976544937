// 3rd Party References
import { createAction } from 'redux-actions';

// Utility References
import { Handler } from '../IndexOfActions';

// ReSharper disable InconsistentNaming

export const WorkflowActionTypes = {
    EXTEND: 'EXTEND',
    NEXT: 'NEXT',
    PREV: 'PREV',
    RESET: 'RESET',
    ROLLBACK: 'ROLLBACK'
};

// ReSharper restore InconsistentNaming

export interface IWorkflowActions {
    extend: Handler.StringArray;
    next: Handler.Action;
    prev: Handler.Action;
    rollback: Handler.StringValue;
}

class WorkflowActions implements IWorkflowActions {

    public extend: Handler.StringArray = createAction(
        WorkflowActionTypes.EXTEND, (newStages: string[]) => newStages
    );

    public next: Handler.Action = createAction(
        WorkflowActionTypes.NEXT
    );

    public prev: Handler.Action = createAction(
        WorkflowActionTypes.PREV
    );

    public rollback: Handler.StringValue = createAction(
        WorkflowActionTypes.ROLLBACK, (targetStageName: string) => targetStageName
    );
}

const workflowActionsDispatcherFactory = (dispatch: Handler.AnyForDispatch): IWorkflowActions => {

    const actions = new WorkflowActions();

    return {
        extend: (newStages: string[]) => {
            dispatch(actions.extend(newStages));
        },
        next: () => {
            dispatch(actions.next());
        },
        prev: () => {
            dispatch(actions.prev());
        },
        rollback: (targetStageName: string) => {
            dispatch(actions.rollback(targetStageName));
        }
    };
};

export { workflowActionsDispatcherFactory as WorkflowActionsDispatcherFactory };
