// Utility References
import { IApiPaginationOptions } from '../IndexOfInterfaces';

const getPaginationOptionForSinglePageView = (): IApiPaginationOptions => {

    return {
        maxVisiblePageButtons: 1,
        pageNumber: 1,
        pageSize: 9999
    };
};

const paginationHelper = {
    getPaginationOptionForSinglePageView
};

export { paginationHelper as PaginationHelper };
