// https://github.com/diegoddox/react-redux-toastr
import * as React from 'react';
import { toastr } from 'react-redux-toastr';

import { TranslationHelper } from 'Utility/IndexOfHelpers';

class HelperComponent extends React.Component<{}, {}>{

    render(){
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

const topFullWidthSetting = {};

function toastrCaller(toastrDisplayMethod: Function, message: string, heading?: string, content?: JSX.Element, toastrOptions?: any /*BasicToastrOptions*/): void {

    const translatedMessage = TranslationHelper.translate(message, message);
    const translatedHeading = (heading) ? TranslationHelper.translate(heading) : '';

    if (content && toastrOptions) {
        toastrOptions.component = (<HelperComponent>{content}</HelperComponent>) as any;
    } else {
        toastrOptions.component = null;
    }

    toastrDisplayMethod(translatedHeading, translatedMessage, toastrOptions);
}

const clickToDismissHelper = (() => {

    const messageContent = (message: string): JSX.Element => {

        return (
            <span>{message}</span>
        );
    };

    const options: any = {
        attention: true,
        showCloseButton: true,
        timeOut: 0
    };

    return {
        contentForError: messageContent,
        contentForInfo: messageContent,
        contentForSuccess: messageContent,
        contentForWarning: messageContent,
        options
    };
})();

export const download = {
    open: (downloadUrl: string, message?: string, heading?: string) => {

        const defaultMessage = message || 'Download Saving.';
        const defaultHeading = heading || 'Download Ready';

        toastrCaller(toastr.info, defaultMessage, defaultHeading, null, topFullWidthSetting);

        // open on a timeout so user has a chance to see Toast Notification.
        window.setTimeout(() => {
            window.open(downloadUrl);
        }, 2000);
    },
    ready: (downloadUrl: string, message?: string, heading?: string) => {

        const defaultMessage = <a className="btn btn-xs btn-primary pull-left" href={downloadUrl}>{TranslationHelper.translate('Click to Download')}</a>;

        const defaultHeading: string = heading || 'Download Ready';

        // TODOxUI: Validate if message is set.
        const messageContent = (
            <span>{message}<br /><br />{defaultMessage}</span>
        );

        toastrCaller(toastr.info, '', defaultHeading, messageContent, clickToDismissHelper.options);
    }
};

export const info = {
    importantInfo: (message: string, heading: string) => {

        const content = clickToDismissHelper.contentForInfo(message);
        toastrCaller(toastr.info, '', heading, content, clickToDismissHelper.options);
    },
    info: (message: string, heading?: string) => {
        toastrCaller(toastr.info, message, heading, null, topFullWidthSetting);
    },
    warning: (message: string = 'Warning', heading?: string) => {

        toastrCaller(toastr.warning, message, heading, null, topFullWidthSetting);
    }
};

export const error = {
    alreadyExists: (message: string = 'Record already exists.', heading?: string) => {

        toastrCaller(toastr.warning, message, heading, null, topFullWidthSetting);
    },
    clickToDismiss: (message: string, heading?: string) => {

        const content = clickToDismissHelper.contentForError(message);
        toastrCaller(toastr.error, '', heading, content, clickToDismissHelper.options);
    },
    concurrencyError: (message: string, heading?: string) => {

        const content = clickToDismissHelper.contentForError(message);
        toastrCaller(toastr.error, '', heading, content, clickToDismissHelper.options);
    },
    connectionError: (message: string = 'Connection Error.<br /><br />Please try again in 5 mins.<br /><br />Contact General Manager if failure continues.', heading?: string) => {

        const content = clickToDismissHelper.contentForError(message);
        toastrCaller(toastr.error, '', heading, content, clickToDismissHelper.options);
    },
    failedToLoad: (message: string = 'Unable to load data. Please try again.', heading?: string) => {

        const content = clickToDismissHelper.contentForError(message);
        toastrCaller(toastr.error, '', heading, content, clickToDismissHelper.options);
    },
    handledException: (message: string, heading: string) => {

        const content = <div dangerouslySetInnerHTML={{ __html: message }}/>;
        toastrCaller(toastr.warning, '', heading, content, topFullWidthSetting);
    },
    missingData: (message: string = 'Please complete all required fields.', heading?: string) => {

        toastrCaller(toastr.warning, message, heading, null, topFullWidthSetting);
    },
    notFound: (message: string = 'No matching records', heading?: string) => {

        toastrCaller(toastr.warning, message, heading, null, topFullWidthSetting);
    },
    throw: (areaName: string, attemptedAction: string, errorDescription: string): void /* return type 'never' in TS2.0 */ => {

        const errorText = `Client Error - ${areaName} - ${attemptedAction} - ${errorDescription}`;

        const content = clickToDismissHelper.contentForError(errorDescription);
        toastrCaller(toastr.error, '', areaName, content, clickToDismissHelper.options);

        //TODOxUI: Implement Client Side "logging" here.
        /* tslint:disable:no-console */
        console.log(`Error: ${errorText}`);
        /* tslint:enable:no-console */

        throw Error(errorText);
    },
    uiException: (message: string) => {
        toastrCaller(toastr.error, message, 'UI Exception', null, topFullWidthSetting);
    },
    unableToSave: (message: string = 'Unable to save record.', heading?: string) => {

        const content = clickToDismissHelper.contentForError(message);
        toastrCaller(toastr.error, '', heading, content, clickToDismissHelper.options);
    },
    unhandledException: (message: string = 'There was an unhandled server exception. Please contact support.', heading?: string) => {

        toastrCaller(toastr.error, message, heading, null, topFullWidthSetting);
    }
};

export const success = {
    authenticated: (message: string = 'Successfully loggedin to MVR website', heading?: string) => {

        toastrCaller(toastr.success, message, heading, null, topFullWidthSetting);
    },
    importantRecordUpdated: (message: string = 'Successfully updated record', heading?: string) => {

        const content = clickToDismissHelper.contentForSuccess(message);
        toastrCaller(toastr.success, '', heading, content, topFullWidthSetting);
    },
    recordCreated: (message: string = 'Successfully saved record', heading?: string) => {

        toastrCaller(toastr.success, message, heading, null, topFullWidthSetting);
    },
    recordLoaded: (message: string = 'Successfully loaded record', heading?: string) => {

        toastrCaller(toastr.success, message, heading, null, topFullWidthSetting);
    },
    recordUpdated: (message: string = 'Successfully updated record', heading?: string) => {

        toastrCaller(toastr.success, message, heading, null, topFullWidthSetting);
    }
};

export const warning = {
    clickToDismiss: (message: string, heading?: string) => {

        const content = clickToDismissHelper.contentForWarning(message);
        toastrCaller(toastr.warning, '', heading, content, clickToDismissHelper.options);
    },
    customMessage: (message: string, heading?: string) => {

        toastrCaller(toastr.warning, message, heading, null, topFullWidthSetting);
    },
    importantPartialRecordUpdated: (message: string = 'Partially updated record', heading?: string) => {

        const content = clickToDismissHelper.contentForWarning(message);
        toastrCaller(toastr.warning, '', heading, content, topFullWidthSetting);
    },
    invalidEntry: (message: string = 'Invalid value entered.', heading: string = 'Invalid Entry') => {

        const content = clickToDismissHelper.contentForWarning(message);
        toastrCaller(toastr.warning, '', heading, content, topFullWidthSetting);
    }
};
