import { IApiItemOptionViewModel } from 'Utility/IndexOfInterfaces';
import { maxInt16 } from './NumberHelper';

export const notApplicableValue = maxInt16();

export function addNotApplicable(toolCollection: IApiItemOptionViewModel[]): void {

    if (toolCollection.filter(x => x.value === maxInt16().toString()).length === 0) {
        toolCollection.push({
            text: 'N/A',
            value: maxInt16().toString()
        });
    }
}
