// 3rd Party References
import { Action, handleActions } from 'redux-actions';

// Utility References
import { ObjectHelper } from '../IndexOfHelpers';
//import {  } from '../IndexOfInterfaces';
import { Notification } from '../IndexOfServices';

// Local References
import { IWorkflowActions, WorkflowActionTypes } from './WorkflowActions';

export interface IWorkflowStage {
    branchSource: string;
    stageName: string;
}

export interface IWorkflowState {
    currentStageIndex: number;
    stages: IWorkflowStage[];
}

const initialState: IWorkflowState = {
    currentStageIndex: 0,
    stages: []
};

// Internal Helper Methods

const internalGetCurrentStageName = (workflowState: IWorkflowState): string => {

    if (workflowState.stages.length > 0) {
        return workflowState.stages[workflowState.currentStageIndex].stageName;
    }

    return '';
};

// Helper
// TODOxUI: Make workflowActions param not optional.
const workflowHelper = (workflowState: IWorkflowState, workflowActions?: IWorkflowActions) => {

    const getCurrentStageName = (): string => {
        return internalGetCurrentStageName(workflowState);
    };

    const isCurrentStage = (stageName: string): boolean => {
        return getCurrentStageName() === stageName;
    };

    const isPriorStage = (stageName: string): boolean => {

        const filteredStages = workflowState.stages.filter(x => x.stageName === stageName);

        if (filteredStages.length === 0) {
            return false;
        }

        const stageToCheck = filteredStages[0];
        const indexOfStageToCheck = workflowState.stages.indexOf(stageToCheck);

        return indexOfStageToCheck < workflowState.currentStageIndex;
    };

    const onStage = (stageName: string) => {

        const advanceWhen = (testCondition: boolean): void => {

            if (workflowActions !== null && isCurrentStage(stageName) && testCondition) {
                workflowActions.next();
            }
        };

        return {
            advanceWhen
        };
    };

    return {
        getCurrentStageName,
        isCurrentStage,
        isPriorStage,
        onStage
    };
};

export { workflowHelper as WorkflowHelper };

// Reducer Helper Methods

const moveBackward = (workflowState: IWorkflowState): IWorkflowState => {

    if (workflowState.currentStageIndex === 0) {

        Notification.error.unhandledException('Attempted move back before Workflow start');
        return workflowState;
    }

    const previousStageIndex = workflowState.currentStageIndex - 1;
    const previousStageName = workflowState.stages[previousStageIndex].stageName;

    const indexOfConditionalStagesToTrim: number = (() => {

        const conditionalStagesToTrim = workflowState.stages.filter(x => x.branchSource === previousStageName);

        const hasConditionalStagesToTrim = conditionalStagesToTrim.length > 0;

        return hasConditionalStagesToTrim ? workflowState.stages.indexOf(conditionalStagesToTrim[0]) : null;

    })();

    const updatedStages: IWorkflowStage[] = (() => {

        if (indexOfConditionalStagesToTrim === null) {

            return workflowState.stages;

        } else {

            const originalStagesClone = workflowState.stages.slice();

            // Trims Conditional Stages From Stack
            originalStagesClone.splice(indexOfConditionalStagesToTrim);

            return originalStagesClone;
        }

    })();

    return { currentStageIndex: previousStageIndex, stages: updatedStages };
};

// Reducer

const workflowReducer = handleActions({
    [WorkflowActionTypes.RESET]: (): IWorkflowState => {
        return initialState;
    },
    [WorkflowActionTypes.EXTEND]: (state: IWorkflowState, action: Action<any>): IWorkflowState => {

        const stageNames = action.payload as string[];

        let branchSource: string;

        if (state.stages.length === 0) {
            branchSource = 'WORKFLOW_START';
        } else {
            branchSource = internalGetCurrentStageName(state);
        }

        const additionalStages = stageNames.map(
            (stageName: string) => ({ branchSource, stageName })
        );

        const allStages = state.stages.concat(...additionalStages);

        return ObjectHelper.merge(state, { stages: allStages });
    },
    [WorkflowActionTypes.NEXT]: (state: IWorkflowState/*, action: Action*/): IWorkflowState => {

        if (state.currentStageIndex === state.stages.length - 1) {
            Notification.error.unhandledException('Attempted move beyond Workflow end');
            return state;
        }

        return ObjectHelper.merge(state, { currentStageIndex: state.currentStageIndex + 1 });
    },
    [WorkflowActionTypes.PREV]: (state: IWorkflowState/*, action: Action*/): IWorkflowState => {
        return moveBackward(state);
    },
    [WorkflowActionTypes.ROLLBACK]: (state: IWorkflowState, action: Action<any>): IWorkflowState => {

        const stageName = action.payload as string;
        let stateCopy = ObjectHelper.deepClone(state);

        while (internalGetCurrentStageName(stateCopy) !== stageName) {
            stateCopy = moveBackward(stateCopy);
        }

        return stateCopy;
    }
}, initialState);

export { workflowReducer as WorkflowReducer };
