// Utility References
import { GridColumnType, GridColumnWidth } from '../IndexOfEnums';
import { IGridColumnNameSet, IStyleWidth } from '../IndexOfInterfaces';

export function getColumnTypeClass(gridColumnType: GridColumnType): string {

    if (!gridColumnType) {
        return '';
    }

    switch (gridColumnType) {
        //case GridColumnType.Any: // TODOxUI: Try to get this back in as it errors
        case GridColumnType.Action:
            return 'action';
        case GridColumnType.Boolean:
        case GridColumnType.String:
        default:
            return '';
        case GridColumnType.Currency:
            return 'currency';
        case GridColumnType.Number:
            return 'numeric';
    }
}

export function getColumnStyleWidthFromSet(gridColumnNameSet: IGridColumnNameSet): IStyleWidth {

    if (!gridColumnNameSet || !gridColumnNameSet.columnWidth) {
        return null;
    }

    return getColumnStyleWidth(gridColumnNameSet.columnWidth);
}

export function getColumnStyleWidth(gridColumnWidth: GridColumnWidth): IStyleWidth {

    switch (gridColumnWidth) {

        case GridColumnWidth.NotSet:
        case GridColumnWidth.Tiny:
            return { minWidth: '55px' };
        case GridColumnWidth.BetweenTinyAndShort:
            return { minWidth: '80px' };
        case GridColumnWidth.Time:
        case GridColumnWidth.YesNo:
        case GridColumnWidth.Short:
            return { minWidth: '110px' };
        case GridColumnWidth.BetweenShortAndMedium:
            return { minWidth: '130px' };
        case GridColumnWidth.Day:
        case GridColumnWidth.Date:
            return { minWidth: '140px' };
        case GridColumnWidth.DateAndTime:
        case GridColumnWidth.Medium:
            return { minWidth: '160px' };
        case GridColumnWidth.BetweenMediumAndLong:
            return { minWidth: '190px' };
        case GridColumnWidth.Long:
            return { minWidth: '220px' };
        case GridColumnWidth.ExtraLong:
            return { minWidth: '260px' };
        case GridColumnWidth.FixedBetweenMediumAndLong:
            return { minWidth: '190px', width: '200px' };
        default:
            return null;
    }
}

export function sortIconPositionedRight(gridColumnType: GridColumnType): boolean {

    if (!gridColumnType) {
        return true;
    }

    switch (gridColumnType) {
        //case GridColumnType.Any: // TODOxUI: Try to get this back in as it errors
        case GridColumnType.Boolean:
        case GridColumnType.String:
        default:
            return true;
        case GridColumnType.Currency:
            return false;
        case GridColumnType.Number:
            return false;
    }
}

export function toggleSiteCompression(hasSiteCompression: boolean, isToggleOn: boolean): void {

    if (hasSiteCompression) {

        const siteCompressionClassName = 'site-compression';

        if (isToggleOn) {

            document.body.classList.add(siteCompressionClassName);
        } else {

            document.body.classList.remove(siteCompressionClassName);
        }
    }
}
