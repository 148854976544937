 import * as moment from 'moment-timezone';

//TODOxUI: Allow for culture settings with date formats.

 export interface IDateConverter {
    toDayOfWeekDisplay(): string;
    toExtendedDateAnd24HrTimeDisplay(): string;
    toIsoFormatDisplay(): string;
    toLongDateAnd24HrTimeDisplay(): string;
    toJsDate(): Date;
    toMomentDate(): moment.Moment;
    toMonthNameDisplay(): string;
    toShortMonthYearDisplay(): string;
    toStandardDateDisplay(): string;
    toStandardDateAnd12HrTimeDisplay(): string;
    toStandardDateAnd24HrTimeDisplay(): string;
    to12HrTimeDisplay(): string;
    to24HrTimeDisplay(): string;
}

 const dateConverter = (date: Date | moment.Moment | string, displayOnFail?: string, useUtc?: boolean): IDateConverter => {

    const dateToMoment = (targetDate: Date | moment.Moment | string): moment.Moment => {

        if (targetDate instanceof Date) {
            return moment(targetDate);
        }

        if (typeof targetDate === 'string') {
            return moment(targetDate);
        }

        return targetDate as moment.Moment;
    };

    const dateAsMoment = useUtc ? dateToMoment(date).utc() : dateToMoment(date);

    const dateIsInvalid = !dateAsMoment || !dateAsMoment.isValid();
    const displayOnFailValue = displayOnFail || '';

    return {
        to12HrTimeDisplay: (): string => {

            if (dateIsInvalid) {
                return displayOnFailValue;
            }

            return dateAsMoment.format('hh:mm a');
        },
        to24HrTimeDisplay: (): string => {

            if (dateIsInvalid) {
                return displayOnFailValue;
            }

            return dateAsMoment.format('HH:mm');
        },
        toDayOfWeekDisplay: (): string => {

            if (dateIsInvalid) {
                return displayOnFailValue;
            }

            return dateAsMoment.format('dddd');
        },
        toExtendedDateAnd24HrTimeDisplay: (): string => {

            if (dateIsInvalid) {
                return displayOnFailValue;
            }

            return dateAsMoment.format('dddd Mo MMMM YYYY HH:mm:ss z');
        },
        toIsoFormatDisplay: (): string => {

            if (dateIsInvalid) {
                return displayOnFailValue;
            }

            return dateAsMoment.format('YYYY-MM-DD');
        },
        toJsDate: (): Date => {

            if (dateIsInvalid) {
                return null;
            }

            return dateAsMoment.toDate();
        },
        toLongDateAnd24HrTimeDisplay: (): string => {

            if (dateIsInvalid) {
                return displayOnFailValue;
            }

            return dateAsMoment.format('MM/DD/YYYY dddd HH:mm');
        },
        toMomentDate: (): moment.Moment => {

            if (dateIsInvalid) {
                return null;
            }

            return dateAsMoment;
        },
        toMonthNameDisplay: (): string => {

            if (dateIsInvalid) {
                return displayOnFailValue;
            }

            return dateAsMoment.format('MMMM');
        },
        toShortMonthYearDisplay: (): string => {

            if (dateIsInvalid) {
                return displayOnFailValue;
            }

            return dateAsMoment.format('MMM YYYY');
        },
        toStandardDateAnd12HrTimeDisplay: (): string => {

            if (dateIsInvalid) {
                return displayOnFailValue;
            }

            return dateAsMoment.format('MM/DD/YYYY hh:mm a');
        },
        toStandardDateAnd24HrTimeDisplay: (): string => {

            if (dateIsInvalid) {
                return displayOnFailValue;
            }

            return dateAsMoment.format('MM/DD/YYYY HH:mm');
        },
        toStandardDateDisplay: (): string => {

            if (dateIsInvalid) {
                return displayOnFailValue;
            }

            return dateAsMoment.format('MM/DD/YYYY');
        }
    };
};

 export interface IDateJoiner {
    resetDatePart(): Date;
    resetTimePart(): Date;
    setDatePart(dateOnlyDate: Date): Date;
    setTimePart(timeOnlyDate: Date): Date;
}

 const dateJoiner = (originalDate: Date): IDateJoiner => {

    const dateCleaner = (date: Date): Date => {

        return moment(date).isValid() ? date : moment('0', 'S').toDate();
    };

    const cleanedOriginal = dateCleaner(originalDate);

    const setDatePart = (dateOnlyDate: Date): Date => {
        const cleanedDate = dateCleaner(dateOnlyDate);

        const year = moment(cleanedDate).year();
        const month = moment(cleanedDate).month();
        const date = moment(cleanedDate).date();

        const dateAsMoment = moment(cleanedOriginal).year(year).month(month).date(date);
        return dateAsMoment.toDate();
    };

    const setTimePart = (timeOnlyDate: Date): Date => {
        const cleanedTime = dateCleaner(timeOnlyDate);

        const hours = moment(cleanedTime).hours();
        const minutes = moment(cleanedTime).minutes();
        const milliseconds = moment(cleanedTime).milliseconds();

        const dateAsMoment = moment(cleanedOriginal).hours(hours).minutes(minutes).milliseconds(milliseconds);
        return dateAsMoment.toDate();
    };

    return {
        resetDatePart: (): Date => {
            return setDatePart(null);
        },
        resetTimePart: (): Date => {
            return setTimePart(null);
        },
        setDatePart,
        setTimePart
    };
};

 export {
    dateConverter as DateConverter,
    dateJoiner as DateJoiner
};
