// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../IndexOfActions';
import { IDictionary } from '../IndexOfInterfaces';

/**
 * Shim to allow adding Aria or Data Attributes to React Prop Objects.
 * @param htmlPropObj React HTML Prop Object to be updated
 */
const setAttribute = <THtmlElement>(htmlPropObj: React.HTMLProps<THtmlElement>) => {

    const internalSetAttribute = (attributeName: string, attributeValue: string): React.HTMLProps<THtmlElement> => {

        ((htmlPropObj as Handler.AnyForInterop) as IDictionary)[attributeName] = attributeValue;
        return htmlPropObj;
    };

    return {
        /**
         * Add Aria Attributes.
         * @param name attribute name
         * @param value attribute value
         */
        aria: (name: string, value: string): React.HTMLProps<THtmlElement> => internalSetAttribute(name, value),
        /**
         * Add Data Attributes.
         * @param name attribute name
         * @param value attribute value
         */
        data: (name: string, value: string): React.HTMLProps<THtmlElement> => internalSetAttribute(name, value)
    };
};

const reactHelper = {
    setAttribute
};

export { reactHelper as ReactHelper };
