// 3rd Party References
import * as React from 'react';

// Internal Functions
interface INumberRange {
    start: number;
    end: number;
}

// Codes from http://www.asciitable.com/ HTML column
const keyCodeInfo = {
    alphaLowerCaseRange: {
        start: 97,
        end: 122
    },
    alphaUpperCaseRange: {
        start: 65,
        end: 90
    },
    enterKey: 13,
    lowerCaseLetterEKey: 101,
    negativeKey: 45,
    numberKeyRange: {
        start: 48,
        end: 57
    },
    periodKey: 46,
    plusKey: 43,
    spaceKey: 32,
    upperCaseLetterEKey: 69
};

const keyCodeChecker = (event: React.KeyboardEvent<HTMLInputElement>) => {

    const actualKeyCode = event.keyCode || event.charCode;

    const isInRange = (numberRange: INumberRange): boolean => {
        return actualKeyCode >= numberRange.start && actualKeyCode <= numberRange.end;
    };

    return {
        isInRange,
        isInRanges: (numberRanges: INumberRange[]): boolean => {
            return numberRanges.some((numberRange: INumberRange) => isInRange(numberRange));
        },
        isMatch: (expectedKeyCode: number): boolean => {
            return actualKeyCode === expectedKeyCode;
        }
    };
};

// Exported Functions

export function delayCallback(): (callback: () => void, ms: number) => void {

    // This is required, resharper doesn't seem to see the usage
    // ReSharper disable once UnusedLocals
    const timer: number = 0;

    return (callback: () => void , ms: number) => {

        clearTimeout(this.timer);

        this.timer = setTimeout(callback, ms);
    };
}

export function isAlphanumericEditingKey(event: React.KeyboardEvent<HTMLInputElement>): boolean {

    return isAlphaKeyPress(event) || isNumericKeyPress(event) || isEnterKeyPress(event);
}

export function isAlphaKeyPress(event: React.KeyboardEvent<HTMLInputElement>): boolean {

    return keyCodeChecker(event).isInRanges([keyCodeInfo.alphaUpperCaseRange, keyCodeInfo.alphaLowerCaseRange]);
}

export function isEnterKeyPress(event: React.KeyboardEvent<HTMLInputElement>): boolean {

    return keyCodeChecker(event).isMatch(keyCodeInfo.enterKey);
}

export function isNumericKeyPress(event: React.KeyboardEvent<HTMLInputElement>): boolean {

    return keyCodeChecker(event).isInRange(keyCodeInfo.numberKeyRange);
}

export function isNumericOrEnterKeyPress(event: React.KeyboardEvent<HTMLInputElement>): boolean {

    return keyCodeChecker(event).isInRange(keyCodeInfo.numberKeyRange) || keyCodeChecker(event).isMatch(keyCodeInfo.enterKey);
}

export function isSpaceKeyPress(event: React.KeyboardEvent<HTMLInputElement>): boolean {

    return keyCodeChecker(event).isMatch(keyCodeInfo.spaceKey);
}

export function isPeriodKeyPress(event: React.KeyboardEvent<HTMLInputElement>): boolean {

    return keyCodeChecker(event).isMatch(keyCodeInfo.periodKey);
}

export function isNegativeKeyPress(event: React.KeyboardEvent<HTMLInputElement>): boolean {

    return keyCodeChecker(event).isMatch(keyCodeInfo.negativeKey);
}

export function isPlusKeyPress(event: React.KeyboardEvent<HTMLInputElement>): boolean {

    return keyCodeChecker(event).isMatch(keyCodeInfo.plusKey);
}

export function isLetterEKeyPress(event: React.KeyboardEvent<HTMLInputElement>): boolean {

    const checker = keyCodeChecker(event);

    return checker.isMatch(keyCodeInfo.lowerCaseLetterEKey) || checker.isMatch(keyCodeInfo.upperCaseLetterEKey);
}

// Model Specific Functions Below
//TODOxUI: Move this to SimpleVRMInput when it is the only one remaining.

/**
 * Handles the rules for allowed chars in registration number input
 * @param event Event from a KeyPress event.
 */
export function isRegNumberInputAllowedKey(event: React.KeyboardEvent<HTMLInputElement>): boolean {

    return isAlphanumericEditingKey(event);
}
