
import { IMenuGroup, IMenuHeader, IMenuItem } from '../../IndexOfInterfaces';

export class MenuGroup implements IMenuGroup {

    constructor(public groupName: string, public to: string, public menuItems: (IMenuItem | IMenuHeader)[]) {
        this.groupName = groupName;
        this.to = to;
        this.menuItems = menuItems;
    }
}

export class MenuItem implements IMenuItem {

    constructor(public name: string, public to: string, public openInNewWindow: boolean, public isTopLevel: boolean) {
        this.isTopLevel = isTopLevel;
        this.name = name;
        this.openInNewWindow = openInNewWindow;
        this.to = to;
    }
}

export class MenuHeader implements IMenuHeader {

    public name: string;

    constructor(name: string) {
        this.name = name;
    }
}
