// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { IGenericDictionary } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

export interface IModalService {
    toggleModal: () => void;
    isModalOpen: () => boolean;
}

export default class ModalService implements IModalService {

    private modalName: string;

    constructor(public dispatch: Handler.AnyForDispatch, public toggleModalFunc: (modalName: string) => void, public pageModals: IGenericDictionary<string>, modalName?: string) {
        this.modalName = modalName;
    }

    public toggleModal = (): void => {

        if (this.assertModalNameValid()) {
            this.dispatch(this.toggleModalFunc(this.modalName));
        }
    }

    public isModalOpen = (): boolean => {

        if (this.assertModalNameValid()) {
            return (this.pageModals && this.pageModals[this.modalName] ? true : false);
        }

        return false;
    }

    private assertModalNameValid = (): boolean => {

        if (this.modalName) {
            return true;
        }

        Notification.error.unhandledException('Modal Name Not Set', 'Modal Failure');
        return false;
    }
}
