// Utility References
import { StatusIndicator } from 'Utility/IndexOfEnums';

export function getBackgroundStateStyle(statusIndicator: number): string {

    let gridDataRow = 'grid-data-row';

    if (statusIndicator === StatusIndicator.Danger) {
        gridDataRow = `${gridDataRow} danger`;
    }

    if (statusIndicator === StatusIndicator.Warning) {
        gridDataRow = `${gridDataRow} warning`;
    }

    if (statusIndicator === StatusIndicator.Success) {
        gridDataRow = `${gridDataRow} success`;
    }

    return gridDataRow;
}
