// Local References
import { IAjaxInfo, IExceptionReport, ISimpleExceptionReport } from '../../IndexOfInterfaces';

export interface ILogger {
    logAjaxInfoException: (ajaxInfo: IAjaxInfo) => void;
    logExceptionReport: (exceptionReport: IExceptionReport, jqxhr: any) => void;
}

/* tslint:disable:no-console */
export default class ConsoleLogger implements ILogger {

    public logAjaxInfoException = (ajaxInfo: IAjaxInfo): void => {

        if (ajaxInfo != null) {
            console.log('Unhandled error', ajaxInfo);
        }
    }

    public logExceptionReport = (exceptionReport: IExceptionReport | ISimpleExceptionReport, jqxhr: any): void => {

        if (exceptionReport != null) {
            console.log('Communication error - Exception Report: ', exceptionReport);
        }
        else if (jqxhr && jqxhr.responseText) {
            console.log('Communication error - Response Text: ', jqxhr.responseText);
        }
        else{
            console.log('General Error: Please contact system administrator');
        }
    }
}
