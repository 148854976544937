// 3rd Party References
import 'whatwg-fetch';

// Utility References
import { AjaxInfoStatusCodes } from 'Utility/IndexOfEnums';
import { ExceptionHelper } from 'Utility/IndexOfHelpers';
import { IAjaxInfo, IApiCall, IApiCallWithPayload } from 'Utility/IndexOfInterfaces';
import { ConsoleLogger } from 'Utility/IndexOfModels';
import { Notification } from 'Utility/IndexOfServices';

const parseResponse = (clearLocalStorage: () => void) => (response: Response) => {

    return new Promise((resolve) => {

        if (response.status === AjaxInfoStatusCodes.HttpNoContent || response.status === AjaxInfoStatusCodes.HttpUnauthorized) {

            resolve({
                json: null,
                ok: response.ok,
                status: response.status
            });

            if (response.status === AjaxInfoStatusCodes.HttpUnauthorized) {
                clearLocalStorage();
            }

            return;
        }

        response.text()
            .then((text) => {

                let json: any = null;

                try {
                    json = JSON.parse(text);
                } catch (e) {
                    json = text;
                }

                resolve({
                    json,
                    ok: response.ok,
                    status: response.status
                });
            });
    });
};

export interface IApiProgressFunctions {
    incrementProgress: Function;
    decrementProgress: Function;
}

type OnFailure = (data: any) => void;

export interface IApi {
    call: <TResponse>(api: IApiCall<TResponse>, onSuccess: (model: TResponse) => void, onFailure: OnFailure) => void;
    callWithPayload: <TRequest, TResponse>(api: IApiCallWithPayload<TRequest, TResponse>, payload: TRequest, onSuccess: (model: TResponse) => void, onFailure: OnFailure, isFormData?: boolean) => void;
}

type authenticationCallback = (func: (bearerToken: string) => void) => void;

const api = (authenticateWithBearerTokenCallback: authenticationCallback, clearLocalStorage: () => void, apiFunctions?: IApiProgressFunctions): IApi => {

    const raiseError = () => {
        Notification.error.clickToDismiss('There was a server error. This could mean that the API is down, or there is a critical error within. Please contact your support team.', 'Server Error');
    };

    const getRequestHeaders = (method: string, bearerToken: string, isFormData?: boolean) => {
        const options: RequestInit = {
            method,
            mode: 'cors',
            headers: {
                Accept: 'application/json, text/plain, */*',
                Culture: (window as any).currentCulture || null
            },
            keepalive: false
        };

        if (bearerToken) {
            options.credentials = 'include';

            const authHeaders = { Authorization: `Bearer ${bearerToken}` };

            options.headers = { ...options.headers, ...authHeaders };
        }

        if (!isFormData) {
            options.headers = { ...options.headers, ...{ 'Content-Type': 'application/json' } };
        }

        return options;
    };

    return {
        call: <TResponse>(api: IApiCall<TResponse>, onSuccess: (model: TResponse) => void, onFailure: OnFailure): void => {

            if (apiFunctions) {
                apiFunctions.incrementProgress();
            }

            const httpFetch = (bearerToken: string) => {

                const options = getRequestHeaders(api.method, bearerToken);

                fetch(api.url, options)
                    .then(parseResponse(clearLocalStorage))
                    .then((response: any) => {

                        if (apiFunctions) {
                            apiFunctions.decrementProgress();
                        }

                        if (response.ok) {
                            const returnedData = response.json as TResponse;

                            onSuccess(returnedData);

                            return;
                        }

                        const returnedData = response.json as IAjaxInfo;

                        const exceptionReport = ExceptionHelper.getExceptionReport(returnedData);

                        const consoleLogger = new ConsoleLogger();
                        consoleLogger.logExceptionReport(exceptionReport, returnedData);

                        onFailure({ dataReturned: returnedData, exceptionReport, statusCode: response.status });

                        return;
                    })
                    .catch((error) => {

                        if (apiFunctions) {
                            apiFunctions.decrementProgress();
                        }

                        raiseError();

                        /* tslint:disable:no-console */
                        console.log('error: ', error);
                        /* tslint:enable:no-console */
                    });
            };

            if (!api.withoutAuth) {
                authenticateWithBearerTokenCallback((bearerToken: string) => {
                    httpFetch(bearerToken);
                });
            } else {
                httpFetch('');
            }
        },
        callWithPayload: <TRequest, TResponse>(api: IApiCallWithPayload<TRequest, TResponse>, payload: TRequest, onSuccess: (model: TResponse) => void, onFailure: OnFailure, isFormData?: boolean): void => {

            const body = !isFormData
                ? payload && JSON.stringify(payload)
                : (payload as any) as FormData;

            if (apiFunctions) {
                apiFunctions.incrementProgress();
            }

            const httpFetch = (bearerToken: string) => {

                const options = getRequestHeaders(api.method, bearerToken, isFormData);
                options.body = body;

                fetch(api.url, options)
                    .then(parseResponse(clearLocalStorage))
                    .then((response: any) => {

                        if (apiFunctions) {
                            apiFunctions.decrementProgress();
                        }

                        if (response.ok) {
                            const returnedData = response.json as TResponse;
                            onSuccess(returnedData);

                            return;
                        }

                        const returnedData = response.json as IAjaxInfo;

                        const exceptionReport = ExceptionHelper.getExceptionReport(returnedData);

                        const consoleLogger = new ConsoleLogger();
                        consoleLogger.logExceptionReport(exceptionReport, returnedData);

                        onFailure({ dataReturned: returnedData, exceptionReport, statusCode: response.status });

                        return;
                    })
                    .catch((error) => {

                        if (apiFunctions) {
                            apiFunctions.decrementProgress();
                        }

                        raiseError();

                        /* tslint:disable:no-console */
                        console.log('error: ', error);
                        /* tslint:enable:no-console */
                    });
            };

            if (!api.withoutAuth) {
                authenticateWithBearerTokenCallback((bearerToken: string) => {
                    httpFetch(bearerToken);
                });
            } else {
                httpFetch('');
            }
        }
    };
};

export { api as Api };
