
// Utility References
import { CultureHelper } from '../../IndexOfHelpers';
import { NumericConverter } from '../../IndexOfModels';

export interface ICurrencyConverter {
    toStandardCurrencyDisplay(): string;
    toCurrencyNoneFractionalDisplay(): string;
}

const currencyConverter = (value: number, displayOnFail?: string): ICurrencyConverter => {

    if (displayOnFail === undefined || displayOnFail === null) {
        displayOnFail = '0';
    }

    return {
        toStandardCurrencyDisplay: (): string => {

            return CultureHelper.getCultureCurrencySymbol() + NumericConverter(value, displayOnFail).toStandardDisplay();
        },
        toCurrencyNoneFractionalDisplay: (): string => {

            return CultureHelper.getCultureCurrencySymbol() + NumericConverter(value, displayOnFail).toNoneFractionalDisplay();
        }
    };
};

export { currencyConverter as CurrencyConverter };
