// Utility References
import * as ObjectHelper from './ObjectHelper';
import * as StringHelper from './StringHelper';

interface ILanguageArray {
    keyLanguage: string;
    translationList: [{ key: string; value: string; }];
}

class TranslationFunctions {

    static getTrans = (reqKey: string, keyLanguage: string): string => {

        let result = reqKey;

        if (StringHelper.isUndefinedNullOrEmpty(reqKey)) {
            return result;
        }

        if (StringHelper.isUndefinedNullOrEmpty(keyLanguage)) {
            keyLanguage = 'en-GB';
        }

        const languageArray = ((window as any).languageArray as ILanguageArray[]);

        if (ObjectHelper.isUndefinedOrNull(languageArray)) {
            return result;
        }

        const translatedKeys = languageArray.find(x => x.keyLanguage === keyLanguage);

        if (ObjectHelper.isUndefinedOrNull(translatedKeys) || ObjectHelper.isUndefinedOrNull(translatedKeys.translationList)) {
            return result;
        }

        const translatedMatch = translatedKeys.translationList && translatedKeys.translationList.find(x => x.key === reqKey);

        if (translatedMatch) {
            result = translatedMatch.value;
        }

        return result;
    }
}

const translate = (key: string, defaultValue?: string): string => {

    const keyLanguage = (window as any).currentCulture;
    const translatedValue = TranslationFunctions.getTrans(key, keyLanguage);

    return translatedValue ? translatedValue : (defaultValue ? defaultValue : key);
};

const dictionary = {
    Address: translate('Address'),
    Amount: translate('Amount'),
    Any: translate('Any'),
    Authorised: translate('Authorized'),
    BillTo: translate('Bill To'),
    Charge: translate('Charge'),
    Day: translate('day'),
    Download: translate('Download'),
    Email: translate('Email'),
    GstHst: translate('GST/HST'),
    Item: translate('Item'),
    Name: translate('Name'),
    NoAddress: translate('No Address'),
    NoData: translate('No Data'),
    NoOptionsAvailable: translate('No Options Available'),
    Notes: translate('Notes'),
    PleaseReselect: translate('Please reselect.'),
    PleaseSelect: translate('Please select'),
    PostalCode: translate('Postal Code'),
    Price: translate('Price'),
    Property: translate('Property'),
    Qst: translate('QST'),
    Qty: translate('Qty'),
    SelectAll: translate('Select all'),
    SubTotal: translate('Sub Total'),
    TaxAmount: translate('Tax Amount'),
    Telephone: translate('Telephone'),
    TotalAmount: translate('Total Amount'),
    Unauthorised: translate('Unauthorized'),
    ValueIsNowInvalid: translate('Value is not invalid.')
};

export {
    dictionary as CommonDictionary,
    translate
};
