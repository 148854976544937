// Utility References
import { IPageActions } from 'App/IndexOfModels';
import { ObjectHelper } from 'Utility/IndexOfHelpers';

type AfterModelUpdateAction<TModel> = (model: TModel) => void;
type ModelUpdateAction<TModel> = (updatedModel: TModel, propertyName?: string) => void;
type PropertyUpdateFunc<TModel, TValue> = (value: TValue, model: TModel) => TModel;

export interface IModelUpdater<TModel> {
    for: <TValue>(propertyUpdateFunc: PropertyUpdateFunc<TModel, TValue>, afterModelUpdateAction?: AfterModelUpdateAction<TModel>) => (value: TValue, propertyName?: string) => void;
}

const modelUpdaterFactory = <TModel>(sourceModel: TModel, modelUpdateAction: ModelUpdateAction<TModel>, modelStateActions?: IPageActions): IModelUpdater<TModel> => {

    const model = ObjectHelper.deepClone(sourceModel);

    return {
        for: <TValue>(propertyUpdateFunc: PropertyUpdateFunc<TModel, TValue>, afterModelUpdateAction?: AfterModelUpdateAction<TModel>) => (value: TValue, propertyName?: string): void => {

            const updatedModel = propertyUpdateFunc(value, model);
            modelUpdateAction(updatedModel, propertyName);

            if (value !== null && value !== undefined && modelStateActions && propertyName) {
                modelStateActions.removeEntry(propertyName);
            }

            if (afterModelUpdateAction) {
                afterModelUpdateAction(updatedModel);
            }
        }
    };
};

export { modelUpdaterFactory as ModelUpdater };
