
// Utility References
import { RouterHelper } from '../IndexOfHelpers';
import { JsonWebTokens } from '../IndexOfInterfaces';

const jsonWebTokenHelper = () => {

    const queryParameterNames = {
        idToken: 'id_token'
    };

    const extractTokenFromCurrentUrl = (): JsonWebTokens.IToken => {

        const tokenAsString = RouterHelper.getQueryParameterValue(queryParameterNames.idToken).asString();

        if (!tokenAsString) {
            return null;
        }

        const [rawHeader, rawPayload, unprocessedSignature ] = tokenAsString.split('.');

        if (!rawHeader || !rawPayload || !unprocessedSignature) {
            return null;
        }

        return {
            header: JSON.parse(atob(rawHeader)),
            payload: JSON.parse(atob(rawPayload)),
            signature: {
                unprocessed: unprocessedSignature
            }
        };
    };

    return {
        extractTokenFromCurrentUrl
    };
};

export { jsonWebTokenHelper as JsonWebTokenHelper };
