 import { IApiSearchViewModel } from '../IndexOfInterfaces';

 export function hasNoRecords<T>(collection: IApiSearchViewModel<T>) {

    return collection === null ||
        collection === undefined ||
        collection.results === null ||
        collection.results === undefined ||
        collection.results.length === 0;
}

 export function hasRecords<T>(collection: IApiSearchViewModel<T>) {

    return !hasNoRecords(collection);
}
