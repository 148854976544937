import { IGenericDictionary } from 'Utility/IndexOfInterfaces';

export function isUndefinedNullOrEmpty(value: any[]): boolean {

    return value === undefined || value === null || value.length === 0;
}

const numberArrayHelper = (array?: number[]) => {

    const checkAllValuesUnique = (): boolean => {

        const uniqueSet = keepUniqueValuesOnly();

        return uniqueSet.length === array.length;
    };

    const keepUniqueValuesOnly = (): number[] => {

        const seen: IGenericDictionary<boolean> = {};

        return array.filter(item => seen.hasOwnProperty(item.toString()) ? false : (seen[item.toString()] = true));
    };

    const sortHighToLow = (a: number, b: number): number => {

        if (a > b) {
            return 1;
        }

        if (a < b) {
            return -1;
        }

        return 0;
    };

    return {
        checkAllValuesUnique,
        keepUniqueValuesOnly,
        sortHighToLow
    };
};

const stringArrayHelper = (array: string[]) => {

    const keepUniqueValuesOnly = (): string[] => {

        const seen: IGenericDictionary<boolean> = {};

        return array.filter(item => seen.hasOwnProperty(item) ? false : (seen[item] = true));
    };

    const checkAllValuesUnique = (): boolean => {

        const uniqueSet = keepUniqueValuesOnly();

        return uniqueSet.length === array.length;
    };

    return {
        checkAllValuesUnique,
        keepUniqueValuesOnly
    };
};

export {
    numberArrayHelper as NumberArrayHelper,
    stringArrayHelper as StringArrayHelper
};
