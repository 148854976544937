
export function setFocus(element: HTMLElement, scroll?: number): void {

    element.focus();

    if (element instanceof HTMLInputElement) {
        (element as HTMLInputElement).select();
    }

    let proportionalScroll = 0.2;

    if (scroll) {
        proportionalScroll = scroll;
    }

    setTimeout(() => {

        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const top = absoluteElementTop - (window.innerHeight * proportionalScroll);

        window.scrollTo(0, top);

    }, 300);
}
