// Base References
import { Internal, PageTypes } from './Base';

const area = '/';

const urls = {
    area,
    welcome: `${area}/welcome`
};

export {
    urls as HomeUrls
};
