import * as moment from 'moment-timezone';

// Utility References
import { isUndefinedOrNull } from './ObjectHelper';

//TODOxUI: Allow for culture settings with date formats.

export function isDefaultDotNetDateValue(date: Date){
    const year = moment(date).year();

    //.NET default date will be year 0001, which will be either 0 or 1 depending on user timezone
    return year === 0 || year === 1;
}

export function now(): Date {

    return moment().toDate();
}

export function today(): Date {

    const todayDate = moment().startOf('day');
    return todayDate.toDate();
}

export function todayAddDays(days: number): Date {

    const date = moment().startOf('day').add('days', days);
    return date.toDate();
}

export function todayAddMonths(months: number): Date {

    const date = moment().startOf('day').add('months', months);
    return date.toDate();
}

export function toUtc(date: Date): Date {

    if (isUndefinedOrNull(date)){
        return null;
    }

    const utcTime = moment(date).utc();
    return utcTime.toDate();
}

export function standardMomentDateFormatPattern(): string {
    return 'DD/MM/YYYY';
}

export function standardMomentDateTimeFormatPattern(): string {
    return 'DD/MM/YYYY HH:mm';
}

export function standardNativeDateFormatPattern(): string {
    return 'dd/MM/yyyy';
}

export function standardNativeDateTimeFormatPattern(): string {
    return 'dd/MM/yyyy HH:mm';
}

export function standardTimeFormatPattern(): string {
    return 'HH:mm';
}

export function todayAsIsoFormatDisplay(): string {

    return moment().format('YYYY-MM-DD');
}

export function yesterday(): Date {

    return moment().subtract(1, 'days').toDate();
}

export function toMoment(date: Date): moment.Moment {

    return moment(date);
}

export function addSeconds(date: Date, seconds: number): Date {
    return new Date(date.getTime() + (seconds * 1000));
}

export function formatDate(date: Date, dateFormat?: string) {

    if (isUndefinedOrNull(date)) {
        return null;
    }

    const format = dateFormat || standardMomentDateTimeFormatPattern();

    return moment(date).format(format);
}
