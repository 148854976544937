// Base References
import { Internal, PageTypes } from './Base';

const area = '/Supply';

const urls = {
    area,
    details: (id?: number | string) => {

        const url = `${area}/${PageTypes.details}`;

        if (id) {
            return `${url}/${id}`;
        }

        return url;
    },
    listingDetails: (id?: number | string) => {

        const url = `${area}/listings/${PageTypes.details}`;

        if (id) {
            return `${url}/${id}`;
        }

        return url;
    },
    listings: `${area}/listings`,
    request: `${area}/request`,
    success: `${area}/success`
};

export {
    urls as SupplyRequestUrls
};
