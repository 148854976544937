// 3rd Party References
import { Route } from 'react-router-dom';

// Utility References
//import {  } from '../IndexOfInterfaces';

export const correctLocalUrls = (href: string): string => {

    return href.indexOf('#') > -1 ? href : `#/${href}`;
};

const getQueryString = () => {

    const href = window.location.href;

    if (!href) {
        return null;
    }

    const urlParts = href.split('#');

    if (!urlParts || urlParts.length < 2) {
        return null;
    }

    return urlParts[1];
};

const getQueryParametersAsKeyValuePairs = () => {

    return getQueryString()
        .split('&')
        .map(parameterToken => {

            const [key, value] = parameterToken.split('=');
            return { key, value };
        });
};

export const getQueryParameterValue = (parameterName: string) => {

    const internalGetQueryParameterValue = (): string => {

        const matchedParameterKeyValuePairs = getQueryParametersAsKeyValuePairs()
            .filter(keyValuePair => keyValuePair.key === parameterName);

        if (matchedParameterKeyValuePairs.length !== 1) {
            return null;
        }

        return matchedParameterKeyValuePairs[0].value;
    };

    return {
        asNumber: (): number => {

            const value = internalGetQueryParameterValue();

            if (value === null) {
                return null;
            }

            const valueAsNumber = parseInt(value, 10);

            return isNaN(valueAsNumber) ? null : valueAsNumber;
        },
        asString: internalGetQueryParameterValue
    };
};
