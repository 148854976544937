 import * as moment from 'moment-timezone';

// Local References
 import { DateConverter } from '../../IndexOfModels';
 import { Notification } from '../../IndexOfServices';

//TODOxUI: Allow for culture settings with date formats.

 export interface IDateComparer {
    difference(targetDate: Date | moment.Moment | string): number;
    isAfter(targetDate: Date | moment.Moment | string): boolean;
    isBefore(targetDate: Date | moment.Moment | string): boolean;
    isSameAs(targetDate: Date | moment.Moment | string): boolean;
    isAfterToday(): boolean;
    isBeforeToday(): boolean;
    isToday(): boolean;
}

 const dateComparer = (sourceDate: Date | moment.Moment | string): IDateComparer => {

    const dateToMoment = (targetDate: Date | moment.Moment | string): moment.Moment => {
        return DateConverter(targetDate).toMomentDate();
    };

    const sourceDateAsMoment = dateToMoment(sourceDate);

    if (!sourceDateAsMoment || !sourceDateAsMoment.isValid()) {

        Notification.error.throw('Utility Date Comparer', 'Compare Dates', 'Invalid Source Date for comparison');
    }

    const assertValid = (targetDateAsMoment: moment.Moment): void => {

        if (!targetDateAsMoment || !targetDateAsMoment.isValid()) {
            Notification.error.throw('Utility Date Comparer', 'Compare Dates', 'Invalid Target Date for comparison');
        }
    };

    return {
        difference: (targetDate: Date | moment.Moment | string): number => {
            const targetDateAsMoment = dateToMoment(targetDate);
            assertValid(targetDateAsMoment);

            return sourceDateAsMoment.diff(targetDate, 'days');
        },
        isAfter: (targetDate: Date | moment.Moment | string): boolean => {

            const targetDateAsMoment = dateToMoment(targetDate);
            assertValid(targetDateAsMoment);

            return sourceDateAsMoment.isAfter(targetDateAsMoment);
        },
        isBefore: (targetDate: Date | moment.Moment | string): boolean => {

            const targetDateAsMoment = dateToMoment(targetDate);
            assertValid(targetDateAsMoment);

            return sourceDateAsMoment.isBefore(targetDateAsMoment);
        },
        isSameAs: (targetDate: Date | moment.Moment | string): boolean => {

            const targetDateAsMoment = dateToMoment(targetDate);
            assertValid(targetDateAsMoment);

            return sourceDateAsMoment.isSame(targetDateAsMoment);
        },
        isAfterToday: (): boolean => {

            return sourceDateAsMoment.isAfter(moment());
        },
        isBeforeToday: (): boolean => {

            return sourceDateAsMoment.isBefore(moment());
        },
        isToday: (): boolean => {

            return sourceDateAsMoment.isSame(moment());
        }
    };
};

 export { dateComparer as DateComparer };
