
export enum GridColumnType {
    Any = 0,
    Action,
    Boolean,
    Currency,
    Number,
    String
}

export enum GridColumnWidth {
    NotSet = 0,
    BetweenMediumAndLong,
    BetweenShortAndMedium,
    BetweenTinyAndShort,
    Date,
    DateAndTime,
    Day,
    ExtraLong,
    FixedBetweenMediumAndLong,
    Long,
    Medium,
    Short,
    Time,
    Tiny,
    YesNo
}
