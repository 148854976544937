// Base References
import { Internal, PageTypes } from './Base';

const area = '/Register';

const urls = {
    area,
    success: `${area}/success`
};

export {
    urls as RegistrationUrls
};
