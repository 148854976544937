
export enum Horizontal {
    Left = 1,
    Center,
    Right,
    Justify,
    NoWrap
}

export const appendToCssClass = (alignment: Horizontal, originalClass?: string): string => {

    const alignmentClassName = (() => {

        switch (alignment) {
            case Horizontal.Left:
                return 'text-left';
            case Horizontal.Center:
                return 'text-center';
            case Horizontal.Right:
                return 'text-right';
            case Horizontal.Justify:
                return 'text-justify';
            case Horizontal.NoWrap:
                return 'text-nowrap';
            default:
                return ''; // Wat u talkin bout willis? Seriously I would like to know. I find your conversations truly fascinating.
        }
    })();

    return !originalClass ? alignmentClassName : originalClass + ' ' + alignmentClassName;
};
