// Utility References
//import {  } from '../IndexOfHelpers';
import { Routing } from '../IndexOfInterfaces';

const returnDelimiter = '<';

export const routePattern = '(/:returnRequired/)*';

export const addToUrl = (url: string, returnUrl: string): string => {

    if (returnUrl) {

        return `${url}/${returnDelimiter}${returnUrl}`;

    } else {

        return url;
    }
};

export const extractParam = (params: Routing.IBaseParamProps, routeParams: Routing.IBaseRouteParamProps): string => {

    return !!params.splat && routeParams.returnRequired === returnDelimiter ? params.splat : null;
};
