// Utility References
import { ObjectHelper } from 'Utility/IndexOfHelpers';

export function valueIsUndefinedOrNullOrNan(value: number) {

    return value === undefined || value === null || isNaN(value);
}

export const isUndefinedNullOrZero = (value: number): boolean => {

    return value === undefined || value == null || value === 0;
};

export function roundTo(numberToRound: number, decimalPlaces: number): number {

    if (valueIsUndefinedOrNullOrNan(numberToRound)) {
        return null;
    }

    const multiplier = Math.pow(10, decimalPlaces);

    return Math.round(numberToRound * multiplier) / multiplier;
}

export function toMoneyDisplay(value: number, decimalPlaces?: number): string {

    if (value === 0) {
        return '0';
    }

    return value !== undefined && value !== null ? (roundTo(value, decimalPlaces || 2)).toFixed(decimalPlaces || 2) : '';
}

export function adjustForNegatives(allowNegatives: boolean, value: number): number {

    if (value === undefined || value === null) {
        return null;
    }

    const shouldInvertNegativeValue = !allowNegatives && value < 0;

    if (shouldInvertNegativeValue) {
        return value * -1;
    }

    return value;
}

export function calculateTaxAmount(amount: number, rate: number) {
    return parseFloat((amount * (rate / 100)).toFixed(2));
}

export function isInt(value: number) {

    return (typeof value === 'number' && (value % 1) === 0);
}

export const maxInt16 = (): number => {
    return 32767;
};

export const maxInt32 = (): number => {
    return 2147483647;
};

export const maxMoney = (): number => {
    return 922337203685477.5807 ;
};

export function percentageDifference(comparison: number, total: number, decimalPlaces?: number) {

    if (comparison === undefined || comparison === null || total === undefined || total === null) {
        return null;
    }

    let result = (100 - ((comparison / total) * 100)) * -1;

    if (decimalPlaces) {
        result = this.roundTo(result, decimalPlaces);
    }

    if (comparison > total) {
        return `+${result}`;
    }

    return result;
}
