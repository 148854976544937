// Direct Reference
import * as StringHelper from './StringHelper';

/**
 * Uses JSON or JQuery to convert object to string then back to object for deep cloning objects.
 * @param obj Object to be cloned
 */
export function deepClone<TModel>(obj: TModel): TModel {

    if (obj === undefined || obj === null) {
        return null;
    }

    const functionFound = containsFunctionProperty(obj);

    if (functionFound) {
        return merge({} as TModel, obj);
    }

    return JSON.parse(JSON.stringify(obj));
}

const containsFunctionProperty = (obj: any): boolean => {

    for (const propName in obj) {

        if (obj.hasOwnProperty(propName)) {

            const property = obj[propName];

            if (typeof property === 'function') {

                return true;

            } else if (typeof property === 'object') {

                if (containsFunctionProperty(property)) {

                    return true;
                }
            }
        }
    }

    return false;
};

// TODOxUI: Get rid of this dirty little hack to fool R#
const assign = (Object as any).assign;

/**
 * Uses deepClone for merging objects.
 * @param targetObj Target Object to be updated
 * @param sourceObject Source Object with values to be applied
 */
export function merge<TModel extends TModelOrBase, TModelOrBase>(targetObject: TModel, sourceObject: TModelOrBase): TModel {

    const isTargetMissing = targetObject === undefined || targetObject === null;
    const isSourceMissing = sourceObject === undefined || sourceObject === null;

    const getTargetObjectClone = () => deepClone(targetObject);
    const getSourceObjectClone = () => deepClone(sourceObject);

    if (isTargetMissing && isSourceMissing) {

        return null;

    } else if (isTargetMissing) {

        return assign({}, getSourceObjectClone()) as TModel;

    } else if (isSourceMissing) {

        return assign({}, getTargetObjectClone());

    } else {

        return assign({}, getTargetObjectClone(), getSourceObjectClone());
    }
}

export function keysToLabels(obj: Object): string[] {

    return Object.keys(obj).map((key) => {
        return StringHelper.toTitleCase(key);
    });
}

/**
 *  Gets property values from a property using bracket notation
 * @param obj
 * @param prop
 */
export function fetchFromObject(obj: any, prop: string): string {

    if (typeof (obj) === 'undefined') {
        return '';
    }

    const index = prop.indexOf('.');

    if (index > -1) {
        return fetchFromObject(obj[prop.substring(0, index)], prop.substr(index + 1));
    }

    return obj[prop];
}

/**
 *  Sets property values to a property using bracket notation
 * @param obj
 * @param prop
 * @param value
 */
export function setToObject(obj: any, prop: string, value: any): void {

    if (typeof (obj) === 'undefined') {
        return;
    }

    const index = prop.indexOf('.');

    if (index > -1) {

        if (!obj[prop.substring(0, index)]) {
            obj[prop.substring(0, index)] = {};
        }

        setToObject(obj[prop.substring(0, index)], prop.substr(index + 1), value);
        return;
    }

    obj[prop] = value;
}

/**
 * Gets the property name from an objects property
 * @param propertyFunction
 */
export function getPropertyName(propertyFunction: any): string {
    return /\.([^\.;]+);?\s*\}$/.exec(propertyFunction.toString())[1];
}

export function isUndefinedOrNull(value: any): boolean {

    return value === undefined || value === null;
}
