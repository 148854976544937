// Utility References
import { Handler/*, PageUIActions*/ } from '../IndexOfActions';
//import {  } from '../IndexOfComponents';
//import {  } from '../IndexOfEnums';
//import {  } from '../IndexOfHelpers';
import { IUtilityPageState } from '../IndexOfInterfaces';
//import {  } from '../IndexOfModels';
//import {  } from '../IndexOfServices';

export interface IControlService {
    isControlLocked: () => boolean;
    toggleLock: Handler.Action;
}

export default class ControlService implements IControlService {

    private controlName: string;

    constructor(public dispatch: Handler.AnyForDispatch, public pageState: IUtilityPageState, controlName: string) {
        this.controlName = controlName;
    }

    public isControlLocked = (): boolean => {

        return (this.pageState.lockedControls && this.pageState.lockedControls[this.controlName] ? true : false);
    }

    public toggleLock = (): void => {

        //this.dispatch(PageUIActions.toggleControlLock(this.controlName));
    }
}
