// 3rd Party References
import * as classNames from 'classnames';

// Utility References
//import {  } from '../IndexOfComponents';
import { IAddButtonProps } from 'Utility/IndexOfComponents';
import { ButtonSize, ButtonStyle } from '../IndexOfEnums';
import { ObjectHelper, StringHelper, TranslationHelper } from '../IndexOfHelpers';
import { IButtonOptions, IGenericQueryActions } from '../IndexOfInterfaces';
import { IControlService, IModalService } from '../IndexOfServices';

interface IClassDictionary {
    [id: string]: boolean | undefined | null;
}

interface IClassArray extends Array<ClassValue> { }

type ClassValue = string | number | IClassDictionary | IClassArray | undefined | null | false;

export function getButtonClasses(defaultButtonOptions: IButtonOptions, extendedButtonOptions?: IButtonOptions, buttonBaseClassOverride?: string): string {

    const defaultButtonOptionsClassObj = getButtonClassObject(defaultButtonOptions, buttonBaseClassOverride);

    if (!extendedButtonOptions) {

        // TODOxUI: This does not seem correct with latest version of classnames
        return classNames(defaultButtonOptionsClassObj);
    }

    const combinedButtonOptions: IButtonOptions = ObjectHelper.merge(defaultButtonOptions, extendedButtonOptions);
    const combinedButtonOptionsClassObj = getButtonClassObject(combinedButtonOptions, buttonBaseClassOverride);

    // TODOxUI: This does not seem correct with latest version of classnames
    return classNames(combinedButtonOptionsClassObj);
}

function getButtonClassObject(buttonOptions: IButtonOptions, buttonBaseClassOverride?: string): IClassArray {

    const buttonBaseClass = buttonBaseClassOverride || 'btn';

    return [
        { [`${buttonBaseClass}`]: true },
        { hidden: buttonOptions.isHidden },
        { 'float-left': buttonOptions.alignLeft },
        { 'float-right': buttonOptions.alignRight },
        { 'btn-block': buttonOptions.buttonBlock },
        { [`${getButtonSize(buttonOptions.buttonSize)}`]: !!buttonOptions.buttonSize },
        { [`${getButtonStyle(buttonOptions.buttonStyle)}`]: !!buttonOptions.buttonStyle },
        { [`${buttonOptions.appendedClassName}`]: !!buttonOptions.appendedClassName }
    ];
}

export function getButtonIsDisabledState(isDisabled: boolean, buttonOptions: IButtonOptions): boolean {

    return isDisabled || (!!buttonOptions && !!buttonOptions.controlService && buttonOptions.controlService.isControlLocked());
}

let hasBeenClicked = false;
const buttonError = new Audio('/content/sounds/click-error.mp3');

export function getButtonOnClickHandler(onClick: (controlService?: IControlService) => void, buttonOptions: IButtonOptions) {

    return () => {

        if (!hasBeenClicked) {

            if (!!buttonOptions && !!buttonOptions.controlService) {

                onClick(buttonOptions.controlService);

            } else {

                onClick();

            }
        } else {
            buttonError.play();
        }

        hasBeenClicked = true;

        setTimeout(() => {
            hasBeenClicked = false;
        },
            500);
    };
}

export function getButtonTitle(buttonOptions: IButtonOptions): string {

    if (!buttonOptions || StringHelper.isUndefinedNullOrEmpty(buttonOptions.title)) {
        return null;
    }

    return TranslationHelper.translate(buttonOptions.title);
}

export function getButtonText(defaultButtonOptions: IButtonOptions, extendedButtonOptions?: IButtonOptions): string {

    const textToUse = extendedButtonOptions && (extendedButtonOptions.buttonText !== null && extendedButtonOptions.buttonText !== undefined)
        ? extendedButtonOptions.buttonText
        : defaultButtonOptions.buttonText;

    return TranslationHelper.translate(textToUse);
}

export function getButtonSize(buttonSize: ButtonSize): string {
    let buttonSizeStyle: string;

    const buttonSizeConstants = {
        Lg: 'btn-lg',
        Md: 'btn-md',
        Sm: 'btn-sm',
        Xl: 'btn-xl',
        Xs: 'btn-xs'
    };

    switch (buttonSize) {
        case ButtonSize.Lg:
            buttonSizeStyle = buttonSizeConstants.Lg;
            break;
        case ButtonSize.Md:
            buttonSizeStyle = buttonSizeConstants.Md;
            break;
        case ButtonSize.Sm:
            buttonSizeStyle = buttonSizeConstants.Sm;
            break;
        case ButtonSize.Xl:
            buttonSizeStyle = buttonSizeConstants.Xl;
            break;
        case ButtonSize.Xs:
            buttonSizeStyle = buttonSizeConstants.Xs;
            break;
        default:
            buttonSizeStyle = '';
            break;
    }

    return buttonSizeStyle;
}

export function getButtonStyle(buttonStyle: ButtonStyle): string {

    let buttonSizeStyle: string;

    const buttonStyleConstants = {
        Danger: 'btn-danger',
        DangerOutline: 'btn-outline-danger',
        Dark: 'btn-dark',
        DarkOutline: 'btn-outline-dark',
        Default: 'btn-default',
        Info: 'btn-info',
        InfoOutline: 'btn-outline-info',
        Light: 'btn-light',
        LightOutline: 'btn-outline-light',
        Link: 'btn-link',
        Primary: 'btn-primary',
        PrimaryOutline: 'btn-outline-primary',
        Secondary: 'btn-secondary',
        SecondaryOutline: 'btn-outline-secondary',
        Success: 'btn-success',
        SuccessOutline: 'btn-outline-success',
        Warning: 'btn-warning',
        WarningOutline: 'btn-outline-warning'
    };

    switch (buttonStyle) {
        case ButtonStyle.Danger:
            buttonSizeStyle = buttonStyleConstants.Danger;
            break;
        case ButtonStyle.DangerOutline:
            buttonSizeStyle = buttonStyleConstants.DangerOutline;
            break;
        case ButtonStyle.Dark:
            buttonSizeStyle = buttonStyleConstants.Dark;
            break;
        case ButtonStyle.DarkOutline:
            buttonSizeStyle = buttonStyleConstants.DarkOutline;
            break;
        case ButtonStyle.Default:
            buttonSizeStyle = buttonStyleConstants.Default;
            break;
        case ButtonStyle.Info:
            buttonSizeStyle = buttonStyleConstants.Info;
            break;
        case ButtonStyle.InfoOutline:
            buttonSizeStyle = buttonStyleConstants.InfoOutline;
            break;
        case ButtonStyle.Link:
            buttonSizeStyle = buttonStyleConstants.Link;
            break;
        case ButtonStyle.Light:
            buttonSizeStyle = buttonStyleConstants.Light;
            break;
        case ButtonStyle.LightOutline:
            buttonSizeStyle = buttonStyleConstants.LightOutline;
            break;
        case ButtonStyle.Primary:
            buttonSizeStyle = buttonStyleConstants.Primary;
            break;
        case ButtonStyle.PrimaryOutline:
            buttonSizeStyle = buttonStyleConstants.PrimaryOutline;
            break;
        case ButtonStyle.Secondary:
            buttonSizeStyle = buttonStyleConstants.Secondary;
            break;
        case ButtonStyle.SecondaryOutline:
            buttonSizeStyle = buttonStyleConstants.SecondaryOutline;
            break;
        case ButtonStyle.Success:
            buttonSizeStyle = buttonStyleConstants.Success;
            break;
        case ButtonStyle.SuccessOutline:
            buttonSizeStyle = buttonStyleConstants.SuccessOutline;
            break;
        case ButtonStyle.Warning:
            buttonSizeStyle = buttonStyleConstants.Warning;
            break;
        case ButtonStyle.WarningOutline:
            buttonSizeStyle = buttonStyleConstants.WarningOutline;
            break;
        default:
            buttonSizeStyle = '';
            break;
    }

    return buttonSizeStyle;
}

export function openUpsertModal(modalService: IModalService, appActions: IGenericQueryActions<{}, {}, {}, {}>, buttonOptions: IButtonOptions, hasPermission: boolean): IAddButtonProps {
    return {
        buttonOptions,
        isDisabled: !hasPermission,
        onClick: () => {
            appActions.getTemplateAsync();
            modalService.toggleModal();
        }
    };
}
