// Utility References
import { DateHelper, LocalStorageHelper } from '../../../Utility/IndexOfHelpers';

// App References
import { IApiAccessToken, IApiUserInfo } from 'App/IndexOfInterfaces';

export interface IApplicationConfig {
    apiUrl: string;
}

// ReSharper restore InconsistentNaming

const clearAll = (): void => {
    LocalStorageHelper.clearStorage();
    sessionStorage.clear();
};

// Application Config

const getApplicationConfig = (): IApplicationConfig => {
    return LocalStorageHelper.retrieveObject('app.config') as IApplicationConfig;
};

const setApplicationConfig = (value: IApplicationConfig) => {
    LocalStorageHelper.storeObject('app.config', value);
};

// User Info

const clearUserInfo = (): void => {
    LocalStorageHelper.storeObject('user.info', null);
};

const isInRole = (roleName: string): boolean => {

    const userInfo = getUserInfo();
    return userInfo !== null && userInfo.roles.map(x => x.name).includes(roleName);
};

const getUserInfo = (): IApiUserInfo => {

    return LocalStorageHelper.retrieveObject('user.info') as IApiUserInfo;
};

const setUserInfo = (value: IApiUserInfo): void => {
    LocalStorageHelper.storeObject('user.info', value);
};

const clearAccessToken = (): void => {
    LocalStorageHelper.storeObject('user.accessToken', null);
};

const getAccessToken = (): IApiAccessToken => {

    const token = LocalStorageHelper.retrieveObject('user.accessToken') as IApiAccessToken;

    if (token) {
        // Convert to proper date
        token.tokenExpiry = new Date(token.tokenExpiry);
    }

    return token;
};

const setAccessToken = (token: IApiAccessToken): void => {

    token.tokenExpiry = new Date(token.tokenExpiry);

    LocalStorageHelper.storeObject('user.accessToken', token);
};

// Export

const localStorageService = {
    clearAll,
    isInRole,
    getApplicationConfig,
    setApplicationConfig,
    clearUserInfo,
    getUserInfo,
    setUserInfo,
    clearAccessToken,
    getAccessToken,
    setAccessToken
};

export { localStorageService as LocalStorageService };
