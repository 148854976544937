import { DashboardUrls } from './Navigation/Urls/DashboardUrls';
import { HomeUrls } from './Navigation/Urls/HomeUrls';
import { LoginUrls } from './Navigation/Urls/LoginUrls';
import { RegistrationUrls } from './Navigation/Urls/RegistrationUrls';
import { SupplyRequestUrls } from './Navigation/Urls/SupplyUrls';

const appUrls = {
    DashboardUrls,
    HomeUrls,
    LoginUrls,
    RegistrationUrls,
    SupplyRequestUrls
};

export {
    appUrls as AppUrls
};
