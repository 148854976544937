
import ControlService, { IControlService } from './Services/ControlService';
import ModalService, { IModalService } from './Services/ModalService';
import * as Notification from './Services/NotificationService';

export {
    ControlService,
    Notification,
    ModalService
};

export {
    IControlService,
    IModalService
};
