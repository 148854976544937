// Utility References
import { IBreadcrumbLink } from 'Utility/IndexOfInterfaces';

const addOptionalIdToUrl = (url: string, optionalId?: number) => {

    if (optionalId) {
        url = url + `/${optionalId}`;
    }

    return url;
};

const internal = {
    HomeBreadcrumb: [{ label: 'Home', route: '/' }] as IBreadcrumbLink[]
};

const pageTypes = {
    cancellation: 'Cancellation',
    delete: 'Delete',
    details: 'Details',
    edit: 'Edit',
    list: 'List',
    lookup: 'Lookup',
    new: 'New',
    remove: 'Remove'
};

export {
    addOptionalIdToUrl as AddOptionalIdToUrl,
    internal as Internal,
    pageTypes as PageTypes
};
