// Local References
import { INotificationInfo } from '../IndexOfInterfaces';
import { Notification } from '../IndexOfServices';

// Urls
import { AppUrls } from 'App/IndexOfUrls';

const redirectPageWithoutNotification = (destination: string, delay: number = 2000) => {

    window.setTimeout(() => {

        // Hashhistory seems bugged in our version. Going to use window.location.href for now, until we are in a place to update our 3rd party libraries
        //hashHistory.push(destination);

        const location = window.location.origin + window.location.pathname;
        const hash = destination.indexOf('/') === 0 ? '#' : '#/';

        window.location.href = `${location}${hash}${destination}`;

    }, delay);
};

const hardRedirectPageWithoutNotification = (destination: string, delay: number = 2000) => {

    window.setTimeout(() => {
        window.location.href = destination;
    }, delay);
};

const openPageInNewTab = (destination: string) => {

    Object.assign(document.createElement('a'), { target: '_blank', href: destination}).click();
};

const redirectPage = (destination: string, notificationInfo: INotificationInfo) => {

    if (notificationInfo) {

        if (!notificationInfo.message) {
            notificationInfo.message = 'Redirecting';
        }

        Notification.info.info(notificationInfo.message, notificationInfo.title);
    }

    redirectPageWithoutNotification(destination);
};

const redirectPageAfterDelay = (destination: string,
                                notificationInfo: INotificationInfo,
                                delay: number = 1000) => {

    window.setTimeout(() => {
        redirectPage(destination, notificationInfo);
    }, delay);
};

const signInRedirect = (branchId: number, roleId: number, notificationInfo: INotificationInfo) => {

    if (notificationInfo) {

        if (!notificationInfo.message) {
            notificationInfo.message = 'Redirecting';
        }

        Notification.info.info(notificationInfo.message, notificationInfo.title);
    }

    hardRedirectPageWithoutNotification(`/${branchId}/${roleId}/#${AppUrls.HomeUrls.area}`);
};

const signOutRedirect = (destination: string, delay: number = 2000) => {

    hardRedirectPageWithoutNotification(destination, delay);
};

export {
    hardRedirectPageWithoutNotification as HardRedirectPageWithoutNotification,
    redirectPage as RedirectPage,
    redirectPageAfterDelay as RedirectPageAfterDelay,
    redirectPageWithoutNotification as RedirectPageWithoutNotification,
    signInRedirect as SignInRedirect,
    signOutRedirect as SignOutRedirect
};
