import * as moment from 'moment-timezone';

import { IApiCultureOptions } from '../IndexOfInterfaces';

export function getCultureInfo(): IApiCultureOptions {

    const d = new Date();
    const timeZoneOffset = d.getTimezoneOffset() * -1;
    const ianaTimeZone = moment.tz.guess();

    // TODOxTJ: Line up with user's selected culture
    return {
        culture: navigator.language,
        ianaTimeZone,
        timeZoneOffset
    };
}

export function getCultureCurrencySymbol(): string {
    //TODOxUI: Need to get currency symbol for culture
    return '$';
}
