 import { IApiItemOptionViewModel } from '../IndexOfInterfaces';

 export function getSelectedOptionItem(selectElement: HTMLSelectElement): IApiItemOptionViewModel {

    const value = selectElement.value;
    const text = ((selectElement.options as HTMLCollection)[selectElement.selectedIndex] as HTMLOptionElement).text;

    return { text, value };
}

 export const getHtmlInputTypes = () => {

    return {
        email: 'email',
        tel: 'tel',
        url: 'url'
    };
};
