// Interface References
import { ObjectHelper } from '../../IndexOfHelpers';
import { IApiFilterBase, IApiPaginationOptions, IApiSortOptions } from '../../IndexOfInterfaces';

type CollectionUpdateFunc = <TFilterOptions extends IApiFilterBase>(options: TFilterOptions) => void;
type FilterUpdateFunc = <TFilter, TValue>(filter: TFilter, value: TValue) => void;

export interface IGridFilterContext {
    filterHandler: (filterUpdateFunc: FilterUpdateFunc) => (filterValue: any) => void;
    paginationHandler: (newPaginationOptions: IApiPaginationOptions) => void;
    sortHandler(newSortOptions: IApiSortOptions): void;
}

const gridFilterContext = <TFilterOptions extends IApiFilterBase>(collectionUpdateFunc: CollectionUpdateFunc, options: TFilterOptions): IGridFilterContext => {

    return {
        filterHandler: (filterUpdateFunc: FilterUpdateFunc) => (filterValue: any): void => {

            const newOptions = ObjectHelper.merge({} as TFilterOptions, options);
            newOptions.paginationOptions.pageNumber = 1;

            filterUpdateFunc((newOptions as any).filterOptions, filterValue);

            collectionUpdateFunc(newOptions);
        },
        paginationHandler: (newPaginationOptions: IApiPaginationOptions): void => {

            const newOptions = ObjectHelper.merge({} as TFilterOptions, options);
            newOptions.paginationOptions = newPaginationOptions;

            collectionUpdateFunc(newOptions);
        },
        sortHandler: (newSortOptions: IApiSortOptions): void => {

            const newOptions = ObjectHelper.merge({} as TFilterOptions, options);

            if (newOptions.paginationOptions) {
                newOptions.paginationOptions.pageNumber = 1;
            }

            newOptions.sortOptions = newSortOptions;

            collectionUpdateFunc(newOptions);
        }
    };
};

export { gridFilterContext as GridFilterContext };
