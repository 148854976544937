export enum CalloutStyle {
    Danger = 1,
    Dark,
    Info,
    Light,
    Muted,
    Primary,
    Secondary,
    Success,
    Warning,
    White
}
