// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    IFormGroupRowWrapperProps,
    IFormGroupWrapperProps,
    ISimpleNumericInputProps,
    ISimpleSelectNumericProps,
    ISimpleStringInputProps,
    ISimpleTelephoneInputProps
} from 'Utility/IndexOfComponents';
import { ObjectHelper, TranslationHelper } from 'Utility/IndexOfHelpers';
import { IApiItemOptionViewModel, IContentWidths } from 'Utility/IndexOfInterfaces';
import { IModelUpdater } from 'Utility/IndexOfModels';

// Interface References
import { IModelState } from '../Interfaces/PageUIInterfaces';

const getContentWidths = (children: string, error: string, formGroup: string, label: string): IContentWidths => {

    return { children, error, formGroup, label };
};

/**
 * Gets IFormGroupWrapperProps
 * @param contentWidths
 * @param modelState
 * @param displayName
 * @param propertyName
 * @param isRequired
 * @param isHidden
 */
const getFormGroupWrapperProps = (contentWidths: IContentWidths, modelState: IModelState, displayName: string, propertyName: string, isRequired?: boolean, isHidden?: boolean): IFormGroupWrapperProps => {

    const translatedDisplayName = TranslationHelper.translate(displayName);

    const formGroupWrapperProps: IFormGroupWrapperProps = {
        contentWidths,
        displayName: translatedDisplayName,
        isHidden,
        isRequired,
        modelState,
        propertyName
    };

    return formGroupWrapperProps;
};

/**
 * Gets IFormGroupRowWrapperProps
 * @param contentWidths
 * @param modelState
 * @param displayName
 * @param propertyName
 * @param isRequired
 * @param isHidden
 */
const getFormGroupRowWrapperProps = (contentWidths: IContentWidths, modelState: IModelState, displayName: string, propertyName: string, isRequired?: boolean, isHidden?: boolean): IFormGroupRowWrapperProps => {

    const translatedDisplayName = TranslationHelper.translate(displayName);

    const formGroupWrapperProps: IFormGroupRowWrapperProps = {
        contentWidths,
        displayName: translatedDisplayName,
        isHidden,
        isRequired,
        modelState,
        propertyName
    };

    return formGroupWrapperProps;
};

type UpdateFunc<T> = (model: T, value: boolean | Date | number | string | string[], properyName: string, namespace?: string) => T;

/**
 * Generic Model Update function using propertName and namespace to set a model's property value
 * @param removeEntryCallback
 */
const updateModel = (removeEntryCallback?: Handler.Action1<string>) => <TViewModel>(model: TViewModel, value: boolean | Date | number | string | string[], propertyName: string, namespace?: string): TViewModel => {

    const property = namespace ? model[namespace] : model;

    if (removeEntryCallback) {
        removeEntryCallback(propertyName);
    }

    property[propertyName] = value;

    return model;
};

/**
 * Gets readonly ISimpleStringInputProps to be used with basic functionality
 * @param value
 */
const getReadonlyStringInputProps = (value: number | string): ISimpleStringInputProps => {

    let valueAsString = '';

    if (!ObjectHelper.isUndefinedOrNull(value)) {
        valueAsString = value.toString();
    }

    return {
        isDisabled: true,
        onChangeCallback: null,
        value: valueAsString
    };
};

/**
 * Gets generic ISimpleNumericInputProps to be used with basic functionality
 * @param propertyName
 * @param value
 * @param modelUpdater
 * @param updateModelFunc
 * @param namespace
 */
const getSimpleNumericInputProps = <TUpsert>(propertyName: string, value: number, modelUpdater: IModelUpdater<TUpsert>, updateModelFunc: UpdateFunc<TUpsert>, namespace?: string): ISimpleNumericInputProps => {
    return {
        onChangeCallback: modelUpdater.for<number>((value, model) => {
            return updateModelFunc(model, value, propertyName, namespace);
        }),
        propertyName,
        value
    };
};

/**
 * Gets generic ISimpleNumericInputProps to be used with basic functionality
 * @param propertyName
 * @param value
 * @param modelUpdater
 * @param updateModelFunc
 * @param namespace
 */
const getSimpleSelectNumericInputProps = <TUpsert>(propertyName: string, value: number, modelUpdater: IModelUpdater<TUpsert>, updateModelFunc: UpdateFunc<TUpsert>, items: IApiItemOptionViewModel[], namespace?: string): ISimpleSelectNumericProps => {
    return {
        items,
        onChangeCallback: modelUpdater.for<number>((value, model) => {
            return updateModelFunc(model, value, propertyName, namespace);
        }),
        propertyName,
        value
    };
};

/**
 * Gets generic ISimpleStringInputProps to be used with basic functionality
 * @param propertyName
 * @param value
 * @param modelUpdater
 * @param updateModelFunc
 * @param maxLength
 * @param namespace
 */
const getSimpleStringInputProps = <TUpsert>(propertyName: string, value: string, modelUpdater: IModelUpdater<TUpsert>, updateModelFunc: UpdateFunc<TUpsert>, maxLength: number = 100, namespace?: string): ISimpleStringInputProps => {
    return {
        maxLength,
        onChangeCallback: modelUpdater.for<string>((value, model) => {
            return updateModelFunc(model, value, propertyName, namespace);
        }),
        propertyName,
        value
    };
};

/**
 * Gets generic ISimpleTelephoneInputProps to be used with basic functionality
 * @param propertyName
 * @param value
 * @param modelUpdater
 * @param updateModelFunc
 * @param maxLength
 * @param namespace
 */
const getSimpleTelephoneInputProps = <TUpsert>(propertyName: string, value: string, modelUpdater: IModelUpdater<TUpsert>, updateModelFunc: UpdateFunc<TUpsert>, maxLength: number = 100, namespace?: string): ISimpleTelephoneInputProps => {
    return {
        onChangeCallback: modelUpdater.for<string>((value, model) => {
            return updateModelFunc(model, value, propertyName, namespace);
        }),
        propertyName,
        value
    };
};

export {
    getContentWidths,
    getFormGroupWrapperProps,
    getReadonlyStringInputProps,
    getSimpleNumericInputProps,
    getSimpleSelectNumericInputProps,
    getSimpleStringInputProps,
    getSimpleTelephoneInputProps,
    updateModel
};
