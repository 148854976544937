
// Utility References
import { INumberKeyedDictionary } from '../IndexOfInterfaces';
import { Notification } from '../IndexOfServices';

type Entry<TValue> = [number, TValue];

export interface INumberKeyedDictionaryHelper<TValue> {
    checkHasOrderedMatchForKeys: (keysToCheck: number[]) => (void);
    checkHasUnorderedMatchForKeys: (keysToCheck: number[]) => boolean;
    clone: () => INumberKeyedDictionary<TValue>;
    getEntries: () => Entry<TValue>[];
    getEntryCount: () => number;
    getKeys: () => number[];
    getValues: () => TValue[];
    map: <TResult>(action: (key: number, value: TValue, index?: number) => TResult) => TResult[];
}

export const numberKeyedDictionaryHelper = <TValue>(numberKeyedDictionary: INumberKeyedDictionary<TValue>): INumberKeyedDictionaryHelper<TValue> => {

    (() => {

        if (!numberKeyedDictionary) {
            Notification.error.throw('Utility Code - NumberKeyedDictionaryHelper', 'Creation', 'Argument Null Exception: numberKeyedDictionary');
        }

    })();

    const map = <TResult>(action: (key: number, value: TValue, index?: number) => TResult): TResult[] => {

        const results: TResult[] = [];
        let index = 0;

        for (const keyAsString in numberKeyedDictionary) {

            if (numberKeyedDictionary.hasOwnProperty(keyAsString)) {

                const value = numberKeyedDictionary[keyAsString];

                const key = parseInt(keyAsString, 10);
                index = index + 1;

                const result = action(key, value, index);
                results.push(result);
            }
        }

        return results;
    };

    const clone = (): INumberKeyedDictionary<TValue> => {

        const cloneDict: INumberKeyedDictionary<TValue> = {};

        map((key: number, value: TValue) => [key, value] as Entry<TValue>)
            .forEach(([key, value]) => cloneDict[key] = value);

        return cloneDict;
    };

    const getKeys = (): number[] => {

        const keys: number[] = [];

        for (const keyAsString in numberKeyedDictionary) {

            if (numberKeyedDictionary.hasOwnProperty(keyAsString)) {
                keys.push(parseInt(keyAsString, 10));
            }
        }

        return keys;
    };

    const getEntries = (): Entry<TValue>[] => {

        const entries: Entry<TValue>[] = [];

        for (const keyAsString in numberKeyedDictionary) {

            if (numberKeyedDictionary.hasOwnProperty(keyAsString)) {

                const value = numberKeyedDictionary[keyAsString];
                const key = parseInt(keyAsString, 10);

                entries.push([key, value]);
            }
        }

        return entries;
    };

    const getEntryCount = (): number => {

        return getKeys().length;
    };

    const getValues = (): TValue[] => {

        const values: TValue[] = [];

        for (const keyAsString in numberKeyedDictionary) {

            if (numberKeyedDictionary.hasOwnProperty(keyAsString)) {

                const value = numberKeyedDictionary[keyAsString];
                values.push(value);
            }
        }

        return values;
    };

    const internalCheckHasMatchForKeys = (keysToCheck: number[], matchPredicate: (value: number, index?: number) => boolean) => {

        const hasCountMatch = getEntryCount() === keysToCheck.length;

        if (hasCountMatch === false) {

            return false;
        }

        const hasNoMatchForKeys = getKeys().some(matchPredicate);

        return (hasNoMatchForKeys === false);
    };

    const checkHasOrderedMatchForKeys = (keysToCheck: number[]): boolean => {

        const matchPredicate = (dictKey: number, index: number) => keysToCheck[index] !== dictKey;
        return internalCheckHasMatchForKeys(keysToCheck, matchPredicate);
    };

    const checkHasUnorderedMatchForKeys = (keysToCheck: number[]): boolean => {

        const matchPredicate = (dictKey: number) => keysToCheck.indexOf(dictKey) === -1;
        return internalCheckHasMatchForKeys(keysToCheck, matchPredicate);
    };

    return {
        checkHasOrderedMatchForKeys,
        checkHasUnorderedMatchForKeys,
        clone,
        getEntries,
        getEntryCount,
        getKeys,
        getValues,
        map
    };
};

export {
    numberKeyedDictionaryHelper as NumberKeyedDictionaryHelper
};

/*
(() => {

    // NumberKeyedDictionaryHelper Tests

    interface ITestItem {
        thing: string;
        thing2: number;
    }

    const testDictionary: INumberKeyedDictionary<ITestItem> = [];
    testDictionary[101] = { thing: 'A101', thing2: 9101 };
    testDictionary[102] = { thing: 'A102', thing2: 9102 };

    const testHelper = numberKeyedDictionaryHelper(testDictionary);

    const keys = testHelper.getKeys();
    const entries = testHelper.getEntries();
    const beTruez = testHelper.checkHasOrderedMatchForKeys([101, 102]);
    const beTruezz = testHelper.checkHasUnorderedMatchForKeys([102, 101]);
    const beFalz = testHelper.checkHasOrderedMatchForKeys([102, 101]);
    const beFalzz = testHelper.checkHasUnorderedMatchForKeys([103, 101]);

})();
*/
