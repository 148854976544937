// 3rd Party References
import * as React from 'react';

// Utility Reference
import { Handler } from 'Utility/IndexOfActions';
import { NumberHelper, ObjectHelper, StringHelper } from 'Utility/IndexOfHelpers';
import { IRequiredField } from 'Utility/IndexOfInterfaces';

/**
 * Helper method to convert an object to FormData.
 */
const objectToFormData = (obj: any, fd: FormData = null): FormData => {

    const formData = fd || new FormData();

    const appendFormData = (data: any, root: string) => {

        root = root || '';

        if (data instanceof File) {

            formData.append(root, data);

        } else if (Array.isArray(data)) {

            for (let i = 0; i < data.length; i++) {

                appendFormData(data[i], root + '[' + i + ']');
            }

        } else if (typeof data === 'object' && data) {

            for (const property in data) {

                if (data.hasOwnProperty(property)) {

                    if (root === '') {

                        appendFormData(data[property], property);

                    } else {

                        appendFormData(data[property], root + '.' + property);

                    }
                }
            }

        } else {

            if (data !== null && typeof data !== 'undefined') {

                formData.append(root, data);
            }

        }
    };

    appendFormData(obj, '');

    return formData;
};

const preventFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
};

const areRequiredPropertiesValid = (clearAction: Handler.Action, addErrorAction: Handler.Action2<string, string>, fieldsForValidation: IRequiredField[]): boolean => {

    clearAction();

    if (ObjectHelper.isUndefinedOrNull(fieldsForValidation) && fieldsForValidation.length === 0) {
        return true;
    }

    const validations = [
        ...fieldsForValidation.map((field) => {
            return isRequiredPropertyValid(addErrorAction, field.propertyName, field.value, field.errorMessage, field.validate || validateRequired);
        })
    ];

    return validations.every(x => x);
};

const isRequiredPropertyValid = (addErrorAction: Handler.Action2<string, string>, propertyName: string, value: string | number | Object, errorMessage: string, validate: (value: string | number | Object) => boolean): boolean => {

    const result = validate(value);

    if (!result) {
        addErrorAction(propertyName, errorMessage);
    }

    return result;
};

const validateRequired = (value: string | number | Object): boolean => {
    let result = true;

    switch (typeof value) {
        case 'string':
            result = !StringHelper.isUndefinedNullOrEmpty(value as string);
            break;
        case 'number':
            result = !NumberHelper.isUndefinedNullOrZero(value as number);
            break;
        case 'object':
            result = Array.isArray(value) ? value.length > 0 : !ObjectHelper.isUndefinedOrNull(value as Object);
            break;
    }

    return result;
};

export {
    areRequiredPropertiesValid,
    isRequiredPropertyValid,
    objectToFormData,
    preventFormSubmit
};
