 import { Action } from 'redux-actions';

// Utility References
 import * as ObjectHelper from './ObjectHelper';

 const actionWrapper = <TState>(state: TState, action: Action<any>, func: (newState: TState, action: Action<any>) => void) => {

    const newState = ObjectHelper.deepClone(state);
    func(newState, action);
    return newState;
};

 const reduxHelper = {
    actionWrapper
};

 export {
    reduxHelper as ReduxHelper
};
