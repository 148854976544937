// Base References
import { Internal, PageTypes } from './Base';

const area = '/Dashboard';

const urls = {

    //Common
    account: `${area}/Account`,
    provisional: `${area}/Provisional`,

    //Insurer
    area: `${area}`,
    declined: `${area}/Declined`,
    pending: `${area}/Pending`,
    running: `${area}/Running`,
    sourced: `${area}/Sourced`,
    archived: `${area}/Archived`,

    //Dealer
    bidding: `${area}/Bidding`,
    lost: `${area}/Lost`,
    won: `${area}/Won`,

    //Supply Listings
    listingDetails: (id?: number | string) => {

        const url = `${area}/listing/details`;

        if (id) {
            return `${url}/${id}`;
        }

        return url;
    },

    //Supply Requests
    supplyRequestDetails: (id?: number | string) => {

        const url = `${area}/supply/details`;

        if (id) {
            return `${url}/${id}`;
        }

        return url;
    }
};

export {
    urls as DashboardUrls
};
