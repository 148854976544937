// Base References
import { Internal, PageTypes } from './Base';

const area = '/Login';

const urls = {
    area,
    changePassword: `${area}/ChangePassword`,
    forgottenPassword: `${area}/ForgottenPassword`,
    resetPassword: `${area}/ResetPassword`,
    impersonate: `${area}/Impersonate`,
    welcome: `${area}/welcome`
};

export {
    urls as LoginUrls
};
