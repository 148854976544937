// Local References
import { CommonExceptions } from '../IndexOfActions';
import { TranslationHelper } from '../IndexOfHelpers';
import { IAjaxInfo, IApiException, IApiExceptionGroup, IExceptionReport, ISimpleExceptionReport } from '../IndexOfInterfaces';
import { Notification } from '../IndexOfServices';

export function getExceptionReport(response: IAjaxInfo): IExceptionReport | ISimpleExceptionReport {

    const fullExceptionReport: IExceptionReport = (response as any) as IExceptionReport;

    return fullExceptionReport;
}

function getStandardNotifications(ajaxInfo: IAjaxInfo, notificationsTitle: string): void {

    const reason = ajaxInfo.exceptionReport && ajaxInfo.exceptionReport.reason || null;

    switch (reason) {
        case CommonExceptions.concurrencyFailure:
            Notification.error.clickToDismiss('Another user has also edited this data. Please reload the page and try again.', notificationsTitle);
            return;
        case CommonExceptions.notFound:
            Notification.error.notFound('No matching records', notificationsTitle);
            return;
        default:
            Notification.error.unhandledException('There was an unhandled exception. Please contact support.', notificationsTitle);
            return;
    }
}

export function exceptionGroupsNotifier(ajaxInfo: IAjaxInfo, exceptionGroups: IApiExceptionGroup[], notificationsTitle: string): void {

    const exceptionArea = (ajaxInfo.exceptionReport && ajaxInfo.exceptionReport.area || null);
    const matchedExceptionGroups = exceptionGroups.filter(exceptionGroup => exceptionGroup.area === exceptionArea);

    if (matchedExceptionGroups.length > 0) {

        const apiException = matchedExceptionGroups[0].exceptions[ajaxInfo.exceptionReport.reason];

        if (apiException !== undefined && apiException !== null) {

            apiException.raise(ajaxInfo, notificationsTitle);
            return;
        }
    }

    getStandardNotifications(ajaxInfo, notificationsTitle);
}

const defaultMessageFormatter = (exceptionReport: IExceptionReport, message: string): string => {

    let finalMessage = message;

    if (exceptionReport && exceptionReport.modelStateErrors) {

        const errorFields: string[] = [];

        for (const key in exceptionReport.modelStateErrors) {

            if (!exceptionReport.modelStateErrors.hasOwnProperty(key)) {
                continue;
            }

            const errors: string[] = exceptionReport.modelStateErrors[key];

            errors.forEach((internalError: string) => {
                errorFields.push(TranslationHelper.translate(internalError));
            });
        }

        if (errorFields.length > 0) {

            const errorListItems = errorFields.map((error) => `<li>${ error }</li>`).join('');
            finalMessage = `<br />${ message }<ul>${ errorListItems }</ul>`;
        }
    }

    return finalMessage;
};

type HandlerForNotifications = (message: string, heading: string) => void;
type CustomMessageFormatter = (exceptionReport: IExceptionReport, message?: string) => string;
const apiException = (message: string, notification: HandlerForNotifications, customMessageFormatter?: CustomMessageFormatter): IApiException => {

    return {
        raise: (ajaxInfo: IAjaxInfo, heading: string) => {

            const exceptionReport = ajaxInfo.exceptionReport;

            const finalMessage = !!customMessageFormatter
                ? customMessageFormatter(exceptionReport, message)
                : defaultMessageFormatter(exceptionReport, message);

            notification(finalMessage, heading);
        }
    };
};

export {
    apiException as ApiException,
    CustomMessageFormatter
};
