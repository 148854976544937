 import { IEnvironmentInfo } from '../IndexOfInterfaces';

 export enum DebugMode {
    NotInDebugMode = 0,
    DebugWithFakedData,
    DebugWithEmptyData
}

 export enum EnvironmentType {
    Development = 1,
    Test,
    UAT,
    Live
}

 export function getCurrentDebugMode(location: Location): DebugMode {

    const href = location.href;
    const rightmostChunk = href.substr(href.length - 5);

    switch (rightmostChunk) {
        case 'FAKED':
            return DebugMode.DebugWithFakedData;
        case 'EMPTY':
            return DebugMode.DebugWithEmptyData;
        default:
            return DebugMode.NotInDebugMode;
    }
}

 function getEnvironmentType(): EnvironmentType {

    const urlPatterns = [
        { environment: EnvironmentType.Development, pattern: '^localhost.*$' },
        { environment: EnvironmentType.Test, pattern: '^bossk-web.*$' },
        { environment: EnvironmentType.UAT, pattern: '^bossk-uat.*$' }
    ];

    let environment: EnvironmentType = EnvironmentType.Live;

    for (const i in urlPatterns) {
        if (urlPatterns.hasOwnProperty(i)) {
            if (new RegExp(urlPatterns[i].pattern, 'i').test(location.host)) {
                environment = urlPatterns[i].environment;
            }
        }
    }

    return environment;
}

 export function getEnvironmentInfo(): IEnvironmentInfo {

    const isDevEnvironment = getEnvironmentType() === EnvironmentType.Development;
    const isNotDevEnvironment = !isDevEnvironment;

    return {
        isDevEnvironment,
        isNotDevEnvironment
    };
}

 export function inDebugWithEmptyDataMode(location: Location): boolean {
    const debugMode = getCurrentDebugMode(location);
    return debugMode === DebugMode.DebugWithEmptyData;
}

 export function isDebugUrl(location: Location): boolean {
    const notInDebugMode = getCurrentDebugMode(location) === DebugMode.NotInDebugMode;
    return !notInDebugMode;
}
