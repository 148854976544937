// Utility References
import { TranslationHelper } from '../../IndexOfHelpers';

export interface IBooleanConverter {
    toTrueFalseDisplay(): string;
    toYesNoDisplay(): string;
}

const booleanConverter = (value: boolean): IBooleanConverter => {

    return {
        toTrueFalseDisplay: (): string => {

            const processedText = value ? 'True' : 'False';
            return TranslationHelper.translate(processedText);
        },
        toYesNoDisplay: (): string => {

            const processedText = value ? 'Yes' : 'No';
            return TranslationHelper.translate(processedText);
        }
    };
};

export { booleanConverter as BooleanConverter };
