export enum ButtonSize {
    Xs = 1,
    Sm,
    Md,
    Lg,
    Xl
}

export enum ButtonStyle {
    Danger = 1,
    DangerOutline,
    Dark,
    DarkOutline,
    Default,
    Info,
    InfoOutline,
    Light,
    LightOutline,
    Link,
    Primary,
    PrimaryOutline,
    Secondary,
    SecondaryOutline,
    Success,
    SuccessOutline,
    Warning,
    WarningOutline
}

export enum ButtonTargetType {
    Default = 1,
    Modal,
    NewTab,
    Redirect
}
