
//TODOxUI: Allow for culture settings with currency formats.

export interface INumericConverter {
    toStandardDisplay(): string;
    toNoneFractionalDisplay(): string;
    toOriginalDisplay(): string;
}

const numericConverter = (value: number, displayOnFail?: string): INumericConverter => {

    const valueIsUndefinedOrNull = value === undefined || value === null;
    const displayOnFailValue = displayOnFail || '';

    return {
        toStandardDisplay: (): string => {

            if (valueIsUndefinedOrNull) { return displayOnFailValue; }
            return value.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        },
        toNoneFractionalDisplay: (): string => {

            if (valueIsUndefinedOrNull) { return displayOnFailValue; }
            return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 });
        },
        toOriginalDisplay: (): string => {
            if (valueIsUndefinedOrNull) { return displayOnFailValue; }
            return value.toString();
        }
    };
};

export { numericConverter as NumericConverter };
