
export function getIconForSpinner(processing: boolean): string {

    let className = 'fa fa-plus';

    if (processing) {
        className = 'fa fa-refresh spinning';
    }

    return className;
}

const kioskViewMode = {
    activate: () => {
        document.getElementById('header').style.display = 'none';
    },
    deactivate: () => {
        document.getElementById('header').style.display = 'block';
    }
};

export { kioskViewMode as KioskViewMode };
