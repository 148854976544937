//e.g. Converts 'My Text Input' to 'myTextInput'
export function toCamelCase(value: string): string {

    const pascalCased = toPascalCase(value);
    return pascalCased[0].toLowerCase() + pascalCased.slice(1);
}

//e.g. Converts 'My Text Input' to 'my-text-input'
export function toHyphenDelimitedIdentifier(value: string): string {

    const matchesAgainstFormat = value.match(/^[a-z-]+$/);
    const alreadyInFormat = !!matchesAgainstFormat;

    if (alreadyInFormat) {
        return value;
    }

    const pascalCased = toPascalCase(value);
    const titleCased = toTitleCase(pascalCased);
    return titleCased.replace(/\s+/g, '-').toLowerCase();
}

//e.g. Converts 'myTextInput' to 'MyTextInput'
export function toPascalCase(value: string): string {

    return value.replace(/\w+/g, w => (w[0].toUpperCase() + w.slice(1)))
        .replace(/\s+/g, '')
        .replace(/-+/g, '')
        .replace(/(Id(?![a-z]))/g, 'ID'); // Matches [MyId | Id | MyIdWord] but not [MyIdentification]
}

//e.g. Converts 'myTextInput' to 'My text input'
export function toSentenceCase(value: string): string {

    const lowerCased = toTitleCase(value).toLowerCase();
    return lowerCased[0].toUpperCase() + lowerCased.slice(1);
}

//e.g. Converts 'myTextInput' to 'My Text Input'
export function toTitleCase(value: string): string {

    return toPascalCase(value)
        .replace(/(ID)/g, '@id@')
        .replace(/([A-Z])/g, ' $1')
        .replace(/(@id@)/g, ' ID')
        .trim();
}

//e.g. Converts 'myTextInputId' to 'My Text Input'
export function toLabelFormat(value: string): string {

    const titleCasedValue = toTitleCase(value);
    const endsWithId = titleCasedValue.slice(-3) === ' ID';

    if (endsWithId) {
        return titleCasedValue.slice(0, -3);
    }

    return titleCasedValue;
}

export function hasSpecialChars(stringToTest: string): boolean {

    //TODOxUI We need to be clearer on what exactly are "special chars"
    const specialCharsRegex: RegExp = new RegExp('[!@#\$%\^\£&*\)\(+=._-]');
    return specialCharsRegex.test(stringToTest);
}

export function hasWhiteSpace(stringToTest: string): boolean {

    return (stringToTest.indexOf(' ') > -1);
}

export function newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
export function isUndefinedNullOrEmpty(stringToTest: string): boolean {
    return stringToTest === undefined || stringToTest === null || stringToTest === '';
}

export const emailRegExPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export function isValidEmailAddress(stringToTest: string): boolean {

    return stringToTest.match(emailRegExPattern) !== null || stringToTest.match(emailRegExPattern) && stringToTest.match(emailRegExPattern).length > 0;
}

export function pluralize(count: number, noun: string, suffix: string = 's'): string {
    return `${noun}${count !== 1 ? suffix : ''}`;
}

export function isMatch(stringOne: string, stringTwo: string, caseSensitive: boolean = false) {
    if (!caseSensitive) {
        stringOne = stringOne.toLowerCase();
        stringTwo = stringTwo.toLowerCase();
    }

    return stringOne === stringTwo;
}

export function isRegexMatch(value: string, pattern: string): boolean {

    return value.match(pattern) !== null || value.match(pattern) && value.match(pattern).length > 0;
}

export function padLeft(sourceString, targetLength, padString) {

    if (isUndefinedNullOrEmpty(sourceString)) {
        return sourceString;
    }

    targetLength = targetLength >> 0;
    padString = isUndefinedNullOrEmpty(padString) ? '' : padString;

    if (sourceString.length >= targetLength) {
        return sourceString;
    }

    targetLength = targetLength - sourceString.length;

    if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length);
    }

    return padString.slice(0, targetLength) + sourceString;
}

export function isInRange(value: string, min: number, max: number): boolean {

    return value.length >= min && value.length <= max;
}
